export enum LoadingSpinnerSize {
  xs = 'xs',
  s = 's',
  m = 'm'
}

export enum LoadingSpinnerColor {
  blue = 'blue',
  green = 'green',
  red = 'red',
  gray = 'gray',
  purple = 'purple',
  yellow = 'yellow',
  newRed = 'new-red'
}
