import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const BoldIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M17.6372 10.8075C18.3585 9.92914 18.8155 8.86402 18.955 7.73609C19.0944 6.60816 18.9106 5.46384 18.4249 4.4363C17.9392 3.40876 17.1717 2.54027 16.2115 1.93193C15.2514 1.32358 14.1382 1.0004 13.0015 1H4.50023C4.10234 1 3.72075 1.15804 3.43941 1.43934C3.15806 1.72064 3 2.10218 3 2.5V21.5C3 21.8978 3.15806 22.2794 3.43941 22.5607C3.72075 22.842 4.10234 23 4.50023 23H14.5018C15.9436 22.9994 17.3444 22.5195 18.4837 21.6359C19.623 20.7522 20.4361 19.5149 20.7952 18.1187C21.1543 16.7224 21.0389 15.2464 20.4672 13.9229C19.8955 12.5994 18.9 11.5034 17.6372 10.8075ZM6.00046 4H13.0015C13.7973 4 14.5605 4.31607 15.1232 4.87868C15.6859 5.44129 16.002 6.20435 16.002 7C16.002 7.79565 15.6859 8.55871 15.1232 9.12132C14.5605 9.68393 13.7973 10 13.0015 10H6.00046V4ZM14.5018 20H6.00046V13H14.5018C15.4302 13 16.3205 13.3687 16.977 14.0251C17.6335 14.6815 18.0023 15.5717 18.0023 16.5C18.0023 17.4283 17.6335 18.3185 16.977 18.9749C16.3205 19.6313 15.4302 20 14.5018 20Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default BoldIcon;
