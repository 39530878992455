import * as React from 'react';

import { FieldModel } from 'shared/models/form';
import { Validator } from 'shared/entities/validator';

import useLocalStore from './useLocalStore';

export type UseModalStateResult = {
  opened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const useEditNameModal = ({
  opened,
  onClose,
  initialValue,
  validators,
  onEditName
}: {
  opened: boolean;
  onClose: () => void;
  initialValue: string;
  validators: Validator<string>[];
  onEditName: (name: FieldModel<string>) => Promise<BaseResponse>;
}): { editNameModel: FieldModel<string>; handleEditName: () => void } => {
  const nameFieldCtx = useLocalStore(
    () => new FieldModel<string>(initialValue, validators)
  );
  const name = nameFieldCtx.store;

  React.useEffect(() => {
    if (opened) {
      name.changeValue(initialValue);
    }
    if (!opened) {
      name.reset();
    }
  }, [opened, initialValue]);

  const handleEditName = React.useCallback(async () => {
    if (!name.touched) {
      onClose();
      return;
    }
    const response = await onEditName(name);
    if (!response.isError) {
      onClose();
    }
  }, [name.touched, onClose]);

  return {
    editNameModel: name,
    handleEditName
  };
};

export default useEditNameModal;
