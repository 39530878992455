import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ResizeUp: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M13.2663 10.7337C13.6213 11.0887 14.1969 11.0887 14.552 10.7337L21.1818 4.10382V8.27273C21.1818 8.7748 21.5888 9.18181 22.0909 9.18181C22.593 9.18181 23 8.7748 23 8.27273V1.90908C23 1.90938 23 1.90879 23 1.90908C23 1.87947 22.9984 1.84891 22.9955 1.81938C22.9942 1.80583 22.9916 1.79267 22.9897 1.77932C22.9874 1.76335 22.9856 1.74736 22.9824 1.73152C22.9794 1.71622 22.9752 1.70135 22.9714 1.68631C22.9679 1.67257 22.965 1.65881 22.9608 1.64523C22.9564 1.63047 22.9509 1.61624 22.9457 1.60184C22.9408 1.58829 22.9364 1.57461 22.9308 1.56123C22.9252 1.54776 22.9187 1.5349 22.9125 1.52177C22.9059 1.508 22.8999 1.49411 22.8926 1.48061C22.8859 1.46804 22.8782 1.4561 22.8709 1.44392C22.863 1.43066 22.8555 1.41724 22.8469 1.40433C22.8379 1.39086 22.8279 1.37821 22.8182 1.36525C22.8101 1.35446 22.8027 1.34338 22.7939 1.3329C22.756 1.28658 22.7135 1.24405 22.6672 1.20604C22.6566 1.19743 22.6456 1.18994 22.6348 1.18184C22.6218 1.17212 22.6092 1.16211 22.5958 1.15312C22.5828 1.14446 22.5693 1.13697 22.5561 1.12908C22.5439 1.12179 22.532 1.11412 22.5194 1.10739C22.5059 1.10019 22.492 1.09405 22.4783 1.08758C22.4651 1.08139 22.4522 1.07484 22.4388 1.06925C22.4255 1.06371 22.4118 1.05924 22.3982 1.05438C22.3838 1.04918 22.3696 1.04369 22.3548 1.03921C22.3412 1.03512 22.3275 1.0321 22.3137 1.02864C22.2987 1.02485 22.2839 1.02063 22.2685 1.0176C22.2527 1.01445 22.2366 1.01258 22.2207 1.01032C22.2073 1.0084 22.1942 1.00589 22.1806 1.00452C22.1512 1.00162 22.1215 1.00005 22.0919 1.00005C22.0922 1.00005 22.0916 1.00005 22.0919 1.00005L15.7273 1C15.2252 1 14.8183 1.40701 14.8183 1.90908C14.8183 2.41116 15.2252 2.81816 15.7273 2.81816H19.8962L13.2663 9.4481C12.9112 9.80317 12.9112 10.3787 13.2663 10.7337Z"
        fill="currentColor"
      />
      <path
        d="M1.00452 22.1806C1.00584 22.1942 1.0084 22.2074 1.01032 22.2207C1.01262 22.2366 1.0145 22.2526 1.0176 22.2685C1.02063 22.2838 1.02485 22.2987 1.02864 22.3137C1.0321 22.3274 1.03508 22.3412 1.03921 22.3548C1.04369 22.3696 1.04918 22.3838 1.05438 22.3982C1.05924 22.4118 1.06371 22.4255 1.06925 22.4388C1.07484 22.4523 1.08136 22.4651 1.08758 22.4782C1.09405 22.492 1.10019 22.5059 1.10739 22.5194C1.11412 22.532 1.12179 22.5439 1.12908 22.5561C1.13701 22.5693 1.14446 22.5828 1.15312 22.5956C1.16211 22.6091 1.17216 22.6218 1.18183 22.6348C1.18993 22.6456 1.1974 22.6566 1.20604 22.6672C1.24405 22.7135 1.28654 22.756 1.3329 22.7939C1.34338 22.8026 1.35446 22.8101 1.36525 22.8182C1.3782 22.8279 1.39086 22.8379 1.40433 22.8469C1.41724 22.8555 1.43066 22.863 1.44392 22.8709C1.4561 22.8782 1.46803 22.886 1.48061 22.8926C1.49411 22.8998 1.508 22.9059 1.52177 22.9125C1.53489 22.9187 1.54781 22.9252 1.56123 22.9308C1.57461 22.9363 1.58829 22.9407 1.60183 22.9456C1.61625 22.9508 1.63048 22.9564 1.64522 22.9609C1.65877 22.9649 1.67257 22.9679 1.68629 22.9714C1.70134 22.9752 1.71617 22.9794 1.73152 22.9824C1.74736 22.9856 1.76339 22.9874 1.77933 22.9897C1.79271 22.9916 1.80583 22.9941 1.81939 22.9955C1.84922 22.9984 1.87913 23 1.90908 23H8.27273C8.77481 23 9.18182 22.593 9.18182 22.0909C9.18182 21.5889 8.77481 21.1818 8.27273 21.1818H4.10382L10.7337 14.5519C11.0888 14.1969 11.0888 13.6213 10.7337 13.2663C10.3787 12.9112 9.80318 12.9112 9.44811 13.2663L2.81817 19.8962V15.7273C2.81817 15.2252 2.41116 14.8182 1.90908 14.8182C1.40701 14.8182 1 15.2252 1 15.7273V22.0909C0.999964 22.1209 1.00158 22.1508 1.00452 22.1806Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ResizeUp;
