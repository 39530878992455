import { differenceInCalendarDays, isBefore } from 'date-fns';

import {
  IBilling,
  IPaymentInfo,
  PaymentInfoServer,
  PaymentState
} from 'shared/entities/payment';
import { normalizeBilling } from 'shared/entities/payment/normalizers';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  BUSINESS_TARIFF_PRICE_FOR_BIG_PRICE,
  BUSINESS_TARIFF_PRICE_FOR_OLD_SPLITS,
  MINIMAL_PAYMENT
} from 'stores/paymentStore/config';
import { CabinetSplit } from 'shared/entities/cabinet';

import ListModel from '../ListModel';

import PaymentMethodModel from './PaymentMethodModel';

export default class PaymentInfoModel implements IPaymentInfo {
  readonly methods: ListModel<PaymentMethodModel>;
  readonly billing: IBilling;
  readonly daysLeft: number;
  readonly dayPrice: number;
  readonly monthPrice: number;
  readonly shouldPay: boolean;
  readonly usersCount: number;
  readonly nextPaymentDate: Date;
  readonly isProfitable: boolean;
  private _minimalPayment: number;
  readonly roundedUsersCount: number;
  readonly rootStore: IRootStore;

  constructor({
    dayPrice,
    monthPrice,
    methods,
    daysLeft,
    billing,
    shouldPay,
    usersCount,
    nextPaymentDate,
    isProfitable,
    roundedUsersCount,
    minimalPayment,
    rootStore
  }: {
    methods: ListModel<PaymentMethodModel>;
    billing: IBilling;
    daysLeft: number;
    dayPrice: number;
    monthPrice: number;
    usersCount: number;
    roundedUsersCount: number;
    shouldPay: boolean;
    nextPaymentDate: Date;
    isProfitable: boolean;
    minimalPayment: number;
    rootStore: IRootStore;
  }) {
    this.methods = methods;
    this.billing = billing;
    this.daysLeft = daysLeft;
    this.dayPrice = dayPrice;
    this.monthPrice = monthPrice;
    this.shouldPay = shouldPay;
    this.usersCount = usersCount;
    this.nextPaymentDate = nextPaymentDate;
    this.isProfitable = isProfitable;
    this._minimalPayment = minimalPayment;
    this.roundedUsersCount = roundedUsersCount;
    this.rootStore = rootStore;
  }
  // TO DO Убрать,  когда бэк пофиксит, что для vip тарифа приходит 0
  get minimalPayment(): number {
    return this._minimalPayment || MINIMAL_PAYMENT;
  }

  get roundedBillingBalance(): number {
    return Math.round(this.billing.balance);
  }

  get balanceString(): string {
    return `${this.roundedBillingBalance.toLocaleString()} ${
      this.rootStore.paymentStore.currency
    }`;
  }

  get dayPriceString(): string {
    return `${Math.round(this.dayPrice).toLocaleString()} ${
      this.rootStore.paymentStore.currency
    }`;
  }

  get monthPriceString(): string {
    return `${Math.round(this.monthPrice).toLocaleString()} ${
      this.rootStore.paymentStore.currency
    }`;
  }

  get corporateMonthPriceString(): string {
    return `${
      this.rootStore.cabinetStore.entity?.split === CabinetSplit.bigPrice0125
        ? BUSINESS_TARIFF_PRICE_FOR_BIG_PRICE
        : BUSINESS_TARIFF_PRICE_FOR_OLD_SPLITS
    } ${this.rootStore.paymentStore.currency}`;
  }

  get state(): PaymentState {
    if (this.billing.balance === 0) {
      if (this.methods.length > 0) {
        return PaymentState.receivingMoneyNotAvailable;
      }
      return PaymentState.subscriptionAvailable;
    }

    if (this.methods.length > 0) {
      return PaymentState.subscriptionActive;
    }
    return PaymentState.subscriptionStopped;
  }

  get isTrialOver(): boolean {
    return isBefore(this.billing.trialUntil, new Date());
  }

  get trialDaysLeft(): number {
    const diff = differenceInCalendarDays(this.billing.trialUntil, new Date());

    return diff > 0 ? diff : 0;
  }

  remove = async (id: string): Promise<BaseResponse> => {
    const method = this.methods.getEntity(id);

    if (!method) {
      return {
        isError: true
      };
    }

    const response = await method.remove();

    if (response.isError) {
      return {
        isError: true
      };
    }

    this.methods.removeEntity(id);

    return {
      isError: false
    };
  };

  static fromJson(
    raw: PaymentInfoServer,
    rootStore: IRootStore
  ): PaymentInfoModel {
    const { entities, keys } = raw.methods.reduce(
      (acc, method) => ({
        ...acc,
        entities: {
          ...acc.entities,
          [method._id]: PaymentMethodModel.fromJson(method, rootStore)
        },
        keys: [...acc.keys, method._id]
      }),
      { entities: {}, keys: [] }
    );

    return new PaymentInfoModel({
      methods: new ListModel<PaymentMethodModel>({ entities, keys }),
      billing: normalizeBilling(raw.billing),
      daysLeft: raw.days_left,
      dayPrice: raw.day_price,
      roundedUsersCount: raw.rounded_users_count,
      monthPrice: raw.month_price,
      shouldPay: raw.should_pay,
      usersCount: raw.users_count,
      nextPaymentDate: raw.next_payment_date
        ? new Date(raw.next_payment_date)
        : new Date(),
      isProfitable: raw.is_profitable,
      minimalPayment: raw.minimal_payment,
      rootStore
    });
  }
}
