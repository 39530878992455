import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const EditIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8989 2.56945C17.6573 1.81063 18.8872 1.81012 19.6463 2.56829L21.4301 4.35007C22.1895 5.10858 22.19 6.33912 21.4313 7.09826L13.0104 15.5235C12.7397 15.7943 12.3952 15.9791 12.0199 16.0547L8.25819 16.8126C7.95968 16.8728 7.65088 16.7795 7.43562 16.5641C7.22036 16.3487 7.12723 16.0398 7.18756 15.7414L7.94688 11.9842C8.02257 11.6097 8.207 11.2658 8.4771 10.9956L16.8989 2.56945ZM18.3614 3.85469C18.3126 3.80598 18.2336 3.80602 18.1849 3.85476L9.76308 12.2809C9.74573 12.2983 9.73389 12.3203 9.72903 12.3444L9.24085 14.7599L11.6608 14.2723C11.6849 14.2675 11.707 14.2556 11.7244 14.2382L20.1453 5.81295C20.194 5.76419 20.194 5.68518 20.1452 5.63647L18.3614 3.85469ZM6.01082 5.4579C4.79983 5.4579 3.81818 6.43956 3.81818 7.65043V17.9893C3.81818 19.2002 4.79983 20.1818 6.01082 20.1818H16.3499C17.5609 20.1818 18.5425 19.2002 18.5425 17.9893L18.5425 12.8199C18.5425 12.3178 18.9495 11.9108 19.4516 11.9108C19.9537 11.9108 20.3607 12.3178 20.3607 12.8199L20.3607 17.9893C20.3607 20.2044 18.565 22 16.3499 22H6.01082C3.79573 22 2 20.2044 2 17.9893V7.65043C2 5.43535 3.79573 3.63972 6.01082 3.63972H11.1804C11.6824 3.63972 12.0894 4.04673 12.0894 4.54881C12.0894 5.05089 11.6824 5.4579 11.1804 5.4579H6.01082Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default EditIcon;
