import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const ResizeDown: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
    >
      <path
        d="M22.9754 1.02458C22.6093 0.658472 22.0156 0.658472 21.6496 1.02458L14.8125 7.86168V3.56251C14.8125 3.04475 14.3928 2.62502 13.875 2.62502C13.3572 2.62502 12.9375 3.04475 12.9375 3.56251V10.125C12.9375 10.1246 12.9375 10.1252 12.9375 10.125C12.9376 10.1555 12.9391 10.187 12.9421 10.2175C12.9435 10.2315 12.9461 10.245 12.9481 10.2587C12.9505 10.2752 12.9525 10.2917 12.9556 10.3081C12.9587 10.3239 12.9631 10.3392 12.967 10.3547C12.9706 10.3689 12.9736 10.3831 12.9779 10.3971C12.9825 10.4122 12.9882 10.427 12.9936 10.4419C12.9986 10.4559 13.0033 10.4699 13.0089 10.4837C13.0146 10.4976 13.0214 10.5109 13.0277 10.5244C13.0345 10.5386 13.0409 10.5529 13.0483 10.5669C13.0551 10.5799 13.0631 10.5921 13.0706 10.6047C13.0787 10.6184 13.0865 10.6322 13.0954 10.6455C13.1046 10.6594 13.115 10.6725 13.125 10.6859C13.1334 10.697 13.141 10.7084 13.15 10.7191C13.1891 10.767 13.233 10.8107 13.2808 10.85C13.2916 10.8589 13.303 10.8666 13.3141 10.875C13.3275 10.885 13.3406 10.8954 13.3545 10.9046C13.3678 10.9135 13.3816 10.9212 13.3953 10.9294C13.4079 10.9369 13.4201 10.9447 13.4331 10.9517C13.447 10.9591 13.4614 10.9655 13.4756 10.9721C13.4891 10.9785 13.5024 10.9854 13.5163 10.9911C13.53 10.9967 13.5441 11.0014 13.5581 11.0064C13.573 11.0117 13.5876 11.0175 13.6029 11.022C13.6169 11.0262 13.6311 11.0294 13.6453 11.033C13.6608 11.0369 13.676 11.0412 13.6919 11.0444C13.7083 11.0476 13.7248 11.0495 13.7411 11.0519C13.755 11.0539 13.7685 11.0564 13.7825 11.0579C13.8129 11.0607 13.8435 11.0625 13.874 11.0625C13.8736 11.0625 13.8744 11.0625 13.874 11.0625H20.4375C20.9553 11.0625 21.375 10.6427 21.375 10.125C21.375 9.60725 20.9553 9.18751 20.4375 9.18751H16.1383L22.9754 2.35041C23.3415 1.9843 23.3415 1.39073 22.9754 1.02458Z"
        fill="currentColor"
      />
      <path
        d="M11.0579 13.7825C11.0565 13.7685 11.0539 13.7549 11.0519 13.7411C11.0495 13.7248 11.0475 13.7083 11.0444 13.6919C11.0412 13.6761 11.0369 13.6608 11.033 13.6453C11.0294 13.6311 11.0264 13.6169 11.022 13.6029C11.0175 13.5876 11.0117 13.573 11.0064 13.5581C11.0014 13.5441 10.9967 13.53 10.9911 13.5163C10.9854 13.5024 10.9786 13.4891 10.9721 13.4756C10.9655 13.4614 10.9591 13.447 10.9517 13.4331C10.9447 13.4201 10.9369 13.4079 10.9294 13.3953C10.9212 13.3816 10.9135 13.3678 10.9046 13.3545C10.8954 13.3406 10.885 13.3275 10.875 13.3141C10.8666 13.303 10.8589 13.2916 10.85 13.2808C10.8107 13.233 10.767 13.1891 10.7191 13.15C10.7084 13.1411 10.697 13.1334 10.6859 13.125C10.6725 13.115 10.6594 13.1046 10.6455 13.0954C10.6322 13.0865 10.6184 13.0787 10.6047 13.0706C10.5921 13.0631 10.5799 13.0551 10.5669 13.0483C10.553 13.0409 10.5386 13.0345 10.5244 13.0277C10.5109 13.0214 10.4976 13.0146 10.4837 13.0089C10.4699 13.0033 10.4559 12.9986 10.4419 12.9936C10.427 12.9882 10.4122 12.9825 10.3971 12.9779C10.3831 12.9737 10.3689 12.9706 10.3547 12.967C10.3392 12.9631 10.324 12.9587 10.3081 12.9556C10.2917 12.9524 10.2752 12.9505 10.2587 12.9481C10.245 12.9461 10.2315 12.9436 10.2175 12.9421C10.1867 12.9391 10.1559 12.9375 10.125 12.9375H3.56251C3.04475 12.9375 2.62502 13.3572 2.62502 13.875C2.62502 14.3928 3.04475 14.8125 3.56251 14.8125H7.86168L1.02458 21.6496C0.658472 22.0156 0.658472 22.6093 1.02458 22.9754C1.39068 23.3415 1.9843 23.3415 2.35041 22.9754L9.18751 16.1383V20.4375C9.18751 20.9553 9.60725 21.375 10.125 21.375C10.6427 21.375 11.0625 20.9553 11.0625 20.4375V13.875C11.0625 13.8441 11.0609 13.8132 11.0579 13.7825Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ResizeDown;
