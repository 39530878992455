import * as React from 'react';

import useEnterClickEventListener from './useEnterClickEventListener';

const useOnEnterClickStopPropagation = (): {
  ref: React.MutableRefObject<HTMLDivElement | null>;
} => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleEnterClick = React.useCallback((e: KeyboardEvent) => {
    e.stopPropagation();
  }, []);

  useEnterClickEventListener({
    handler: handleEnterClick,
    getElement: () => ref.current
  });

  return { ref };
};

export default useOnEnterClickStopPropagation;
