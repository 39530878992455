import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const ItalicIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M22 2.57143C22 2.9882 21.8336 3.3879 21.5375 3.6826C21.2414 3.9773 20.8398 4.14286 20.4211 4.14286H16.2961L11.0329 19.8571H14.1053C14.524 19.8571 14.9256 20.0227 15.2217 20.3174C15.5179 20.6121 15.6842 21.0118 15.6842 21.4286C15.6842 21.8453 15.5179 22.245 15.2217 22.5397C14.9256 22.8344 14.524 23 14.1053 23H3.57895C3.16018 23 2.75857 22.8344 2.46246 22.5397C2.16635 22.245 2 21.8453 2 21.4286C2 21.0118 2.16635 20.6121 2.46246 20.3174C2.75857 20.0227 3.16018 19.8571 3.57895 19.8571H7.70395L12.9671 4.14286H9.89474C9.47597 4.14286 9.07436 3.9773 8.77825 3.6826C8.48214 3.3879 8.31579 2.9882 8.31579 2.57143C8.31579 2.15466 8.48214 1.75496 8.77825 1.46026C9.07436 1.16556 9.47597 1 9.89474 1H20.4211C20.8398 1 21.2414 1.16556 21.5375 1.46026C21.8336 1.75496 22 2.15466 22 2.57143Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ItalicIcon;
