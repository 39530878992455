import cn from 'classnames';
import * as React from 'react';

import { CloseIcon } from 'shared/newComponents/icons';
import { noop } from 'shared/entities/common/utils';
import { ComponentType } from 'shared/entities/components/Component';
import { TranslationNode } from 'shared/entities/localization';
import TransComp from 'shared/components/TransComp';

import RightOption from './components/RightOption';

import './ContentWindow.scss';

export type RightOptionConfig = {
  key: string;
  Component: React.ElementType<Record<string, any>>;
  componentProps?: Record<string, any>;
};

export type ContentWindowProps = {
  title?: TranslationNode;
  children?: React.ReactNode;
  onClose?: () => void;
  optionOnClick?: (key: string) => void;
  rightOptions?: RightOptionConfig[];
  scroll?: boolean;
  isContainer?: boolean;
};

type Props = ContentWindowProps & {
  contentRef?: React.RefObject<HTMLDivElement>;
  className?: string;
};

const ContentWindow: React.FC<Props> = ({
  onClose,
  optionOnClick = noop,
  rightOptions = [],
  children = null,
  title = '',
  contentRef,
  className,
  scroll = false,
  isContainer
}: Props) => {
  return (
    <div
      className={cn(
        'content-window',
        isContainer && 'content-window_is-container',
        className
      )}
    >
      <div className="content-window__header">
        <div className="content-window__title">
          <TransComp>{title}</TransComp>
        </div>
        <div className="content-window__options">
          {rightOptions?.map(({ Component, key, componentProps }) => (
            <RightOption
              key={key}
              type={key}
              comp={Component}
              className="content-window__options-item"
              onClick={optionOnClick}
              compProps={componentProps}
            />
          ))}
          {onClose && (
            <CloseIcon
              className="content-window__options-item"
              action={{
                type: ComponentType.svg,
                onClick: onClose
              }}
            />
          )}
        </div>
      </div>
      <div
        className={cn(
          'content-window__content',
          scroll && 'content-window__content_scroll'
        )}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentWindow;
