import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const ChatGPTIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M8.90247 13.4446V6.85295L14.0248 3.89585C16.8628 2.25709 21.9439 6.29907 19.8108 9.99773"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.90247 10.2404L14.6107 6.94458L19.733 9.90168C22.5711 11.5404 21.6098 17.9628 17.3389 17.9628"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.6765 8.63818L17.3847 11.934V17.8528C17.3847 21.1303 11.3424 23.5107 9.20923 19.812"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.4505 10.3594V16.832L9.32817 19.7891C6.49008 21.4279 1.409 17.3859 3.54214 13.6873"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.4505 13.4445L8.74229 16.7403L3.62 13.7832C0.777339 12.1399 1.73863 5.72217 6.00949 5.72217"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.6765 15.0466L5.96826 11.7508V5.83201C5.96826 2.55448 12.0106 0.17415 14.1438 3.87282"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ChatGPTIcon;
