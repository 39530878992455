import * as React from 'react';
import { observer } from 'mobx-react';

import Stub from 'shared/newComponents/Stub';
import { useVKCreationStore } from 'stores/VKCreationStore';
import { ComponentType } from 'shared/entities/components/Component';
import { AddUserIcon } from 'shared/newComponents/icons';

const NotConnectedStub: React.FC = () => {
  const vkCreationStore = useVKCreationStore();

  return (
    <Stub
      title={(t) =>
        t('ChannelsModal.ChannelForm.VK.stubs.NotConnected.title', {
          ns: 'newContainers'
        })
      }
      description={(t) =>
        t('ChannelsModal.ChannelForm.VK.stubs.NotConnected.description', {
          ns: 'newContainers'
        })
      }
      button={{
        type: ComponentType.button,
        onClick: vkCreationStore.connectAccount,
        Icon: AddUserIcon,
        loadingStage: vkCreationStore.gettingAuthUrlStage.value,
        children: (t) =>
          t('ChannelsModal.ChannelForm.VK.stubs.NotConnected.button', {
            ns: 'newContainers'
          })
      }}
    />
  );
};

export default observer(NotConnectedStub);
