import { IRootStore } from 'shared/entities/store/rootStore';
import {
  AppNotificationType,
  BannerPriority
} from 'shared/entities/appNotifications';

import { PollingModel, TimeoutHandlerType } from './PollingModel';

const BANNER_ID = 'POP_UP_WINDOW_BANNER';

export class PollingWindowModel extends PollingModel {
  private openedWindow: Window | null = null;
  private rootStore: IRootStore;

  constructor({
    rootStore,
    cb
  }: {
    rootStore: IRootStore;
    cb: TimeoutHandlerType;
  }) {
    super({
      cb,
      cbOnMaxAttempts: () => {
        rootStore.appNotificationsStore.open({
          title: (t) =>
            t('PollingModel.notifications.timeExceeded', {
              ns: 'models'
            }),
          type: AppNotificationType.error
        });

        return {
          isError: false
        };
      }
    });
    this.rootStore = rootStore;
  }

  private closeWindow = () => {
    if (!this.openedWindow) {
      return;
    }
    this.openedWindow.close();
    this.openedWindow = null;
  };

  private _openWindow = (windowUrl: string): void => {
    this.openedWindow = window.open(windowUrl);

    if (!this.openedWindow) {
      this.rootStore.appNotificationsStore.openBanner({
        id: BANNER_ID,
        type: AppNotificationType.warning,
        title: (t) =>
          t('PollingModel.notifications.windows', {
            ns: 'models'
          }),
        priority: BannerPriority.extra
      });
    }
  };

  closePollingBanner = (): void => {
    this.rootStore.appNotificationsStore.closeBanner(BANNER_ID);
  };

  stopPolling = (): void => {
    if (!this.timeout) {
      return;
    }

    this.closeWindow();
    super.stopPolling();
  };

  openWindow(windowUrl: string): Promise<BaseResponse> {
    this._openWindow(windowUrl);

    return super.startPolling();
  }
}
