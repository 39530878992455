import * as React from 'react';
import cn from 'classnames';

import {
  ContentModalProps,
  ModalSize
} from 'shared/newEntities/components/modals';

import Modal from '../Modal';

import './ContentModal.modules.scss';

const ContentModal: React.FC<ContentModalProps> = ({
  children,
  fixHeight = true,
  size = ModalSize.l,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      styleName={cn(
        'content-modal',
        fixHeight && 'content-modal_fix-height',
        `content-modal_${size}`
      )}
    >
      {children}
    </Modal>
  );
};

export default ContentModal;
