import * as React from 'react';

import FormModal from 'shared/newComponents/modals/FormModal';
import { BaseModalProps } from 'shared/newEntities/components/modals';
import { ComponentType } from 'shared/entities/components/Component';
import Typography from 'shared/newComponents/Typography';
import {
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import TypedTransComponent from 'shared/components/TypedTransComponent';

const CongratulationsModal: React.FC<BaseModalProps> = (props) => {
  return (
    <FormModal
      withoutCancelButton
      title={(t) =>
        t('ChannelsModal.ChannelForm.WhatsApp.CongratulationsModal.title', {
          ns: 'newContainers'
        })
      }
      ok={{
        type: ComponentType.button,
        onClick: props.onClose,
        children: (t) =>
          t('ChannelsModal.ChannelForm.WhatsApp.CongratulationsModal.button', {
            ns: 'newContainers'
          })
      }}
      {...props}
    >
      <Typography fontType={TypographyType.text} fontSize={TypographySize.m}>
        <TypedTransComponent
          ns="newContainers"
          i18nKey="ChannelsModal.ChannelForm.WhatsApp.CongratulationsModal.content"
        >
          Благодарим за ваш интерес к продукту!
          <br />
          <br />
          Интеграция еще в разработке, но мы получили вашу заявку и сообщим,
          когда WhatsApp будет доступен в сервисе \uD83D\uDE09
        </TypedTransComponent>
      </Typography>
    </FormModal>
  );
};

export default CongratulationsModal;
