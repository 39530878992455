import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const CopyIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 20'
      }}
      {...props}
    >
      <g clipPath="url(#clip0_2801_67454)">
        <path
          d="M4.16675 12.5C3.39018 12.5 3.00189 12.5 2.69561 12.3732C2.28723 12.204 1.96277 11.8795 1.79362 11.4712C1.66675 11.1649 1.66675 10.7766 1.66675 10V4.33335C1.66675 3.39993 1.66675 2.93322 1.8484 2.5767C2.00819 2.2631 2.26316 2.00813 2.57676 1.84834C2.93328 1.66669 3.39999 1.66669 4.33341 1.66669H10.0001C10.7767 1.66669 11.1649 1.66669 11.4712 1.79355C11.8796 1.96271 12.2041 2.28717 12.3732 2.69555C12.5001 3.00183 12.5001 3.39012 12.5001 4.16669M10.1667 18.3334H15.6667C16.6002 18.3334 17.0669 18.3334 17.4234 18.1517C17.737 17.9919 17.992 17.7369 18.1518 17.4233C18.3334 17.0668 18.3334 16.6001 18.3334 15.6667V10.1667C18.3334 9.23327 18.3334 8.76656 18.1518 8.41004C17.992 8.09643 17.737 7.84147 17.4234 7.68168C17.0669 7.50002 16.6002 7.50002 15.6667 7.50002H10.1667C9.23333 7.50002 8.76662 7.50002 8.4101 7.68168C8.09649 7.84147 7.84153 8.09643 7.68174 8.41004C7.50008 8.76656 7.50008 9.23327 7.50008 10.1667V15.6667C7.50008 16.6001 7.50008 17.0668 7.68174 17.4233C7.84153 17.7369 8.09649 17.9919 8.4101 18.1517C8.76662 18.3334 9.23333 18.3334 10.1667 18.3334Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2801_67454">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default CopyIcon;
