import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const AlignBlockIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M3 19C2.44772 19 2 18.5523 2 18V18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18V18C22 18.5523 21.5523 19 21 19H3ZM8 8C7.44772 8 7 7.55228 7 7V6C7 5.44772 7.44772 5 8 5H16C16.5523 5 17 5.44772 17 6V7C17 7.55228 16.5523 8 16 8H12H8ZM8 14C7.44772 14 7 13.5523 7 13V12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12V13C17 13.5523 16.5523 14 16 14H8Z"
        fill="currentColor"
      />
      <path d="M12 8V11V14V17" stroke="currentColor" />
    </BaseIcon>
  );
};

export default AlignBlockIcon;
