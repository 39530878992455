const createUrl = (url: string): string => `/shopback_api/${url}`;

export const shopbackApiUrls = {
  PRODUCT_CREATE: createUrl('product/create'),
  PRODUCT_EDIT: createUrl('product/edit'),
  PRODUCT_GET: createUrl('product/get'),
  PRODUCT_LIST: createUrl('product/list'),
  MODIFICATION_LIST: createUrl('product/list_modifications'),
  PRODUCT_DELETE: createUrl('product/delete'),
  PRODUCT_EDIT_CATEGORY: createUrl('product/edit_category'),
  PRODUCT_IMPORT: createUrl('product/import'),

  PROJECT_LIST: createUrl('project/list'),
  PROJECT_CREATE: createUrl('project/create'),
  PROJECT_EDIT: createUrl('project/edit'),
  PROJECT_GET: createUrl('project/get'),
  PROJECT_DELETE: createUrl('project/delete'),

  ATTACHMENT_UPLOAD: createUrl('attachment/upload'),

  CATEGORY_GET: createUrl('category/get'),
  CATEGORY_DELETE: createUrl('category/delete'),
  CATEGORY_LIST: createUrl('category/list'),
  CATEGORY_CREATE: createUrl('category/create'),
  CATEGORY_EDIT: createUrl('category/edit'),

  OPTIONS_LIST: createUrl('product/options/list'),
  OPTIONS_DELETE: createUrl('product/options/delete'),
  OPTIONS_EDIT: createUrl('product/options/edit'),
  MODIFICATORS_LIST: createUrl('product/modificators/list'),
  MODIFICATORS_CREATE: createUrl('product/modificators/create'),
  MODIFICATORS_EDIT: createUrl('product/modificators/edit'),
  MODIFICATORS_DELETE: createUrl('product/modificators/delete')
};
