import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const AttachIcon: React.FC<CommonIconProps> = ({
  svgProps,
  ...rest
}: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24',
        ...svgProps
      }}
      {...rest}
    >
      <path
        d="M18.02 5.16409C17.2688 4.41711 16.2535 3.99615 15.1941 3.99241C14.1347 3.98867 13.1165 4.40243 12.36 5.14409L5.69999 11.6881C5.51141 11.8745 5.2565 11.9784 4.99134 11.9769C4.72617 11.9754 4.47246 11.8686 4.28599 11.6801C4.1937 11.587 4.12072 11.4767 4.07124 11.3553C4.02177 11.234 3.99678 11.104 3.99771 10.973C3.99864 10.842 4.02547 10.7124 4.07666 10.5918C4.12785 10.4711 4.20239 10.3618 4.29599 10.2701L10.954 3.72809C12.0868 2.61618 13.612 1.99541 15.1993 2.00027C16.7866 2.00514 18.3081 2.63525 19.434 3.75409C19.9926 4.30929 20.4358 4.96945 20.7383 5.6966C21.0408 6.42375 21.1965 7.20353 21.1965 7.99109C21.1965 8.77864 21.0408 9.55842 20.7383 10.2856C20.4358 11.0127 19.9926 11.6729 19.434 12.2281L10.77 20.8561C10.0333 21.5884 9.03674 21.9994 7.99799 21.9994C6.95925 21.9994 5.9627 21.5884 5.22599 20.8561C4.86214 20.4942 4.5734 20.064 4.37638 19.5902C4.17935 19.1163 4.07792 18.6082 4.07792 18.0951C4.07792 17.5819 4.17935 17.0738 4.37638 16.6C4.5734 16.1262 4.86214 15.6959 5.22599 15.3341L13.306 7.28809C13.4934 7.10123 13.7473 6.9963 14.012 6.9963C14.2767 6.9963 14.5305 7.10123 14.718 7.28809C14.8106 7.38043 14.8841 7.49015 14.9343 7.61096C14.9845 7.73176 15.0103 7.86128 15.0103 7.99209C15.0103 8.12289 14.9845 8.25241 14.9343 8.37322C14.8841 8.49402 14.8106 8.60374 14.718 8.69609L6.63999 16.7421C6.46167 16.9194 6.32015 17.1302 6.22358 17.3624C6.12702 17.5946 6.0773 17.8436 6.0773 18.0951C6.0773 18.3466 6.12702 18.5956 6.22358 18.8278C6.32015 19.06 6.46167 19.2708 6.63999 19.4481C7.00103 19.8081 7.49011 20.0103 7.99999 20.0103C8.50988 20.0103 8.99895 19.8081 9.35999 19.4481L18.02 10.8201C18.3927 10.4495 18.6885 10.0088 18.8904 9.5235C19.0922 9.03816 19.1961 8.51772 19.1961 7.99209C19.1961 7.46645 19.0922 6.94601 18.8904 6.46067C18.6885 5.97534 18.3927 5.5347 18.02 5.16409Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AttachIcon;
