import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const VarIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XM}
      svgProps={{
        viewBox: '0 0 20 18'
      }}
      {...props}
    >
      <path
        d="M16.5884 16.5C17.7053 14.2407 18.3334 11.6941 18.3334 9C18.3334 6.30592 17.7053 3.7593 16.5884 1.5M3.41163 1.5C2.29471 3.7593 1.66669 6.30592 1.66669 9C1.66669 11.6941 2.29471 14.2407 3.41163 16.5M13.7905 6.1875H13.7158C13.1713 6.1875 12.654 6.42668 12.2996 6.84227L7.82061 12.0952C7.46626 12.5108 6.94894 12.75 6.40444 12.75H6.32971M7.26238 6.1875H8.42412C8.84051 6.1875 9.20645 6.46498 9.32084 6.86745L10.7995 12.0701C10.9139 12.4725 11.2799 12.75 11.6963 12.75H12.858"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default VarIcon;
