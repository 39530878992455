import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const DndArrowIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M8.92308 5.07692L12 2M12 2L15.0769 5.07692M12 2V22M8.92308 18.9231L12 22M12 22L15.0769 18.9231M18.9231 8.92308L22 12M22 12L18.9231 15.0769M22 12H2M5.07692 8.92308L2 12M2 12L5.07692 15.0769"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default DndArrowIcon;
