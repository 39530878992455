import * as React from 'react';

import { BaseModalProps } from 'shared/newEntities/components/modals';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { TranslationString } from 'shared/entities/localization';
import { ComponentType } from 'shared/entities/components/Component';
import { CloseIcon } from 'shared/newComponents/icons';
import { ButtonColor, ButtonSize } from 'shared/newEntities/components/Button';
import { Button } from 'shared/newComponents/buttons';
import { ReferenceProps } from 'shared/newEntities/components/Reference';
import Reference from 'shared/newComponents/Reference';

import BaseModal from '../BaseModal';

import './Modal.modules.scss';

type Props = React.PropsWithChildren<
  BaseModalProps & {
    title: TranslationString | string;
    headerExtraComponent?: React.ReactNode;
    headerRightComponent?: React.ReactNode;
    className?: string;
    titleFontSize?: TypographySize.l | TypographySize.m;
    reference?: ReferenceProps;
  }
>;

const Modal: React.FC<Props> = ({
  opened,
  onClose,
  children,
  className,
  title,
  headerExtraComponent,
  headerRightComponent,
  titleFontSize = TypographySize.l,
  reference
}) => {
  return (
    <BaseModal
      opened={opened}
      onClose={onClose}
      styleName="modal__content"
      className={className}
    >
      <div styleName="modal__content-header">
        <div styleName="modal__content-header-left">
          <Typography
            fontType={TypographyType.text}
            fontSize={titleFontSize}
            fontWeight={TypographyWeight.semiBold}
            styleName="modal__content-header-left-title"
          >
            {title}
          </Typography>
          {reference && <Reference {...reference} />}
          {headerExtraComponent && headerExtraComponent}
        </div>
        <div styleName="modal__content-header-right">
          {headerRightComponent && headerRightComponent}
          <Button
            type={ComponentType.button}
            onClick={onClose}
            styleName="modal__back-button"
            Icon={CloseIcon}
            size={ButtonSize.sm}
            color={ButtonColor.gray}
          />
        </div>
      </div>
      <Typography
        fontType={TypographyType.text}
        fontSize={TypographySize.m}
        fontWeight={TypographyWeight.normal}
        styleName="modal__content-main"
      >
        {children}
      </Typography>
    </BaseModal>
  );
};

export default Modal;
