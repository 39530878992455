import * as React from 'react';
import { Link } from 'react-router-dom';

import { IconComponent, IconSize } from 'shared/entities/components/Icon';
import {
  ComponentSvgProps,
  ComponentType
} from 'shared/entities/components/Component';

type RightComponentProps = {
  className?: string;
  Icon: IconComponent;
  action:
    | {
        type: ComponentType.a;
        href: string;
        onClick?: React.MouseEventHandler<HTMLAnchorElement>;
      }
    | {
        type: ComponentType.link;
        to: string;
        onClick?: React.MouseEventHandler<HTMLAnchorElement>;
      }
    | ComponentSvgProps;
};

const RightIconComponent: React.FC<RightComponentProps> = ({
  Icon,
  action,
  className
}: RightComponentProps) => {
  if (action.type === ComponentType.link) {
    return (
      <Link className={className} to={action.to} onClick={action.onClick}>
        <Icon iconSize={IconSize.XM} />
      </Link>
    );
  }

  if (action.type === ComponentType.a) {
    return (
      <Icon iconSize={IconSize.XM} action={action} className={className} />
    );
  }

  return <Icon action={action} iconSize={IconSize.XM} className={className} />;
};

export default RightIconComponent;
