import {
  ChannelMailingServer,
  ChannelMailingStatusServer,
  MailingStatus
} from './server';
import { IChannelMailing, ChannelMailingStatus } from './client';

export const normalizeMailingChannel = (
  raw: ChannelMailingServer
): IChannelMailing => ({
  usersMatched: raw.users_matched,
  usersTotal: raw.users_total,
  reach: raw.reach || null,
  channelStatus: {
    status: MailingStatus.draft,
    progress: 0,
    chunksSent: 0,
    chunksTotal: 0,
    messagesReceived: 0,
    messagesSent: 0
  },
  id: raw._id,
  photoUrl: raw.photo_url ?? null,
  ...raw
});

export const normalizeMailingChannels = (
  raw: ChannelMailingServer[]
): IChannelMailing[] => {
  return raw.map((channel) => ({
    ...normalizeMailingChannel(channel)
  }));
};

export const normalizeChannelMailingStatus = (
  raw: ChannelMailingStatusServer
): ChannelMailingStatus => ({
  chunksSent: raw.chunks_sent,
  chunksTotal: raw.chunks_total,
  messagesSent: raw.messages_sent,
  messagesReceived: raw.messages_received,
  ...raw
});
