import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const AddRowGoogleSheetsIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M2.39999 2.4C2.39999 1.07452 3.47451 0 4.79999 0H14.4L21.6 7.2V21.6C21.6 22.9255 20.5255 24 19.2 24H4.79999C3.47451 24 2.39999 22.9255 2.39999 21.6V2.4Z"
        fill="#129A5A"
      />
      <path
        d="M12 10.8999V17.8999M8.5 14.3999H15.5"
        stroke="white"
        strokeWidth="1.00001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.3"
        d="M14.4 0L21.6 7.2H16.8C15.4745 7.2 14.4 6.12548 14.4 4.8V0Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default AddRowGoogleSheetsIcon;
