import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

const UpdateIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M8.54661 19.7675C10.9457 20.8318 13.8032 20.774 16.2502 19.3613C20.3157 17.0141 21.7086 11.8155 19.3614 7.75005L19.1114 7.31703M4.63851 16.2502C2.2913 12.1847 3.68424 6.98616 7.74972 4.63895C10.1967 3.22618 13.0542 3.16838 15.4533 4.23274M2.49341 16.3338L5.22546 17.0659L5.95751 14.3338M18.0426 9.66587L18.7747 6.93382L21.5067 7.66587"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default UpdateIcon;
