import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const UnderlineIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M21 21.625C21 21.9897 20.8503 22.3394 20.5838 22.5973C20.3173 22.8551 19.9558 23 19.5789 23H4.42105C4.04417 23 3.68272 22.8551 3.41622 22.5973C3.14972 22.3394 3 21.9897 3 21.625C3 21.2603 3.14972 20.9106 3.41622 20.6527C3.68272 20.3949 4.04417 20.25 4.42105 20.25H19.5789C19.9558 20.25 20.3173 20.3949 20.5838 20.6527C20.8503 20.9106 21 21.2603 21 21.625ZM12 18.4167C14.135 18.4145 16.182 17.593 17.6916 16.1322C19.2013 14.6714 20.0504 12.6908 20.0526 10.625V2.375C20.0526 2.01033 19.9029 1.66059 19.6364 1.40273C19.3699 1.14487 19.0085 1 18.6316 1C18.2547 1 17.8932 1.14487 17.6267 1.40273C17.3602 1.66059 17.2105 2.01033 17.2105 2.375V10.625C17.2105 11.9621 16.6616 13.2445 15.6844 14.19C14.7072 15.1355 13.3819 15.6667 12 15.6667C10.6181 15.6667 9.29276 15.1355 8.3156 14.19C7.33844 13.2445 6.78947 11.9621 6.78947 10.625V2.375C6.78947 2.01033 6.63976 1.66059 6.37326 1.40273C6.10676 1.14487 5.74531 1 5.36842 1C4.99153 1 4.63008 1.14487 4.36358 1.40273C4.09709 1.66059 3.94737 2.01033 3.94737 2.375V10.625C3.94956 12.6908 4.79867 14.6714 6.30835 16.1322C7.81804 17.593 9.86498 18.4145 12 18.4167Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UnderlineIcon;
