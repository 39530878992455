import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const GoogleSheetsIcon: React.FC<CommonIconProps> = (
  props: CommonIconProps
) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M2.40002 2.4C2.40002 1.07452 3.47454 0 4.80002 0H14.4L21.6 7.2V21.6C21.6 22.9255 20.5255 24 19.2 24H4.80002C3.47454 24 2.40002 22.9255 2.40002 21.6V2.4Z"
        fill="#129A5A"
      />
      <path
        opacity="0.3"
        d="M14.4 0L21.6 7.2H16.8C15.4745 7.2 14.4 6.12548 14.4 4.8V0Z"
        fill="white"
      />
      <path
        d="M6.59998 15.0004H17.4M6.59998 15.0004V12.6004C6.59998 11.9377 7.13724 11.4004 7.79998 11.4004H10.2M6.59998 15.0004V17.4005C6.59998 18.0632 7.13724 18.6005 7.79998 18.6005H10.2M17.4 15.0004V17.4005C17.4 18.0632 16.8627 18.6005 16.2 18.6005H10.2M17.4 15.0004V12.6004C17.4 11.9377 16.8627 11.4004 16.2 11.4004H10.2M10.2 11.4004V18.6005"
        stroke="white"
        strokeWidth="1.12501"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default GoogleSheetsIcon;
