import * as React from 'react';
import { MutableRefObject } from 'react';

import { ExternalLinkProps } from 'shared/newEntities/components/ExternalLink';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographyType
} from 'shared/newEntities/components/Typography';

const ExternalLink = React.forwardRef(
  (
    {
      rel = 'noreferrer noopener',
      target = '_blank',
      fontType = TypographyType.text,
      fontColor = TypographyColor.link,
      ...rest
    }: ExternalLinkProps,
    ref: MutableRefObject<HTMLAnchorElement>
  ) => {
    return (
      <Typography
        Element="a"
        ref={ref}
        rel={rel}
        target={target}
        fontType={fontType}
        fontColor={fontColor}
        {...rest}
      ></Typography>
    );
  }
);

export default React.memo(ExternalLink);
