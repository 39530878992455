import { IUser, UserServer } from 'shared/entities/user';

export default class UserModel implements IUser {
  readonly name: string | null;

  readonly email: string;

  readonly confirmed: boolean;

  readonly id: string;

  // сделать когда появится
  avatar?: string | null = null;

  constructor({ name, email, confirmed, id }: IUser) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.confirmed = confirmed;
  }

  static fromJson(raw: UserServer): UserModel {
    return new UserModel({
      id: raw._id,
      email: raw.email,
      name: raw.name,
      confirmed: raw.confirmed
    });
  }
}
