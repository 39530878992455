import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';

import { TextAreaProps } from 'shared/newEntities/components/TextArea';
import WithBorder from 'shared/newComponents/WithBorder';
import {
  useMaxLengthValidator,
  useModalState
} from 'shared/entities/common/hooks';
import {
  renderTranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import CopyComponent from 'shared/newComponents/CopyComponent';

import WithMeta from '../WithMeta';

import './TextArea.modules.scss';

type Props = TextAreaProps & {
  className?: string;
};

const TextArea: React.FC<Props> = ({
  error,
  label,
  className = '',
  bottomLabel,
  onBlur,
  placeholder,
  onChange,
  maxRows = 8,
  withCopying,
  fontSize = 'm',
  maxLength,
  value,
  ...rest
}) => {
  const { opened: focused, open: focus, close: unfocus } = useModalState();
  const { t } = useTypedTranslation();

  const handleBlur = React.useCallback(
    (e) => {
      onBlur?.(e);
      unfocus();
    },
    [onBlur, unfocus]
  );

  const { maxLengthValidatorProps, maxLengthError } = useMaxLengthValidator(
    maxLength,
    handleBlur
  );

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    (e) => {
      onChange(e.target.value, e);
    },
    [onChange]
  );

  const actualError = maxLengthError ?? error;

  return (
    <WithMeta
      error={actualError}
      label={label}
      bottomLabel={bottomLabel}
      className={className}
    >
      <div styleName="textarea__container">
        <WithBorder
          focus={focused}
          error={!!actualError}
          disabled={rest.disabled}
          styleName={cn(
            'textarea__left',
            withCopying && 'textarea__left_with-copy'
          )}
        >
          <TextareaAutosize
            {...rest}
            {...maxLengthValidatorProps}
            value={value}
            placeholder={placeholder && renderTranslationString(placeholder, t)}
            minRows={4}
            maxRows={maxRows}
            onFocus={focus}
            onChange={handleChange}
            spellCheck={false}
            styleName={cn('textarea', `textarea_${fontSize}`)}
          />
        </WithBorder>
        {withCopying && (
          <div styleName="textarea__copying">
            <CopyComponent value={value} />
          </div>
        )}
      </div>
    </WithMeta>
  );
};

export default React.memo(TextArea);
