import * as React from 'react';
import TextInput from 'react-autocomplete-input';
import cn from 'classnames';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { observer } from 'mobx-react';

import { MAX_TEXT_LENGTH, ValidatorResult } from 'shared/entities/validator';
import { ResizeDown, ResizeUp, SmileIcon } from 'shared/newComponents/icons';
import { IconSize, IconStyle } from 'shared/entities/components/Icon';
import {
  useEnterClickEventListener,
  useEventListener,
  useLocal,
  useModalState
} from 'shared/entities/common/hooks';
import { ComponentType } from 'shared/entities/components/Component';
import EmojiStore from 'stores/emojiStore';
import { useRootStore } from 'stores/index';
import { DefaultSettingsEmoji } from 'shared/entities/emoji';
import { PopupContainer } from 'shared/components/popups';
import {
  PopupEventType,
  PopupPosition
} from 'shared/entities/components/Popup';
import {
  renderTranslationString,
  TranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import WithBorder from 'shared/newComponents/WithBorder';
import { WithMetaProps } from 'shared/newEntities/components/WithMeta';

import WithMeta from '../WithMeta';

import {
  AutoCompleteComponent,
  AutoCompleteFontSize,
  AutoCompleteSize
} from './config';

import './AutoComplete.scss';
import './AutoComplete.modules.scss';

type Props = {
  options?: string[];
  value: string;
  onChange: (value: string) => void;
  changeOnSelect?: (trigger: string, slug: string) => string;
  onBlur?: () => void;
  withBorder?: boolean;
  trigger?: string;
  regex?: string;
  Component?: AutoCompleteComponent;
  placeholder?: TranslationString | string;
  right?: React.ReactNode;
  error?: ValidatorResult;
  showError?: boolean;
  className?: string;
  size?: number;
  maxLength?: number;
  spacer?: string;
  withEmoji?: boolean;
  withAutofocus?: boolean;
  withoutHandleEnter?: boolean;
  withMetaKey?: boolean;
  fullHeight?: boolean;
  withResize?: boolean;
  disabled?: boolean;
  autoCompleteSize?: AutoCompleteSize;
  fontSize?: AutoCompleteFontSize;
} & WithMetaProps;

const DEFAULT_TRIGGER = '@';

const TEXTAREA_HEIGHT_WITHOUT_RESIZE = 36;
const TEXTAREA_HEIGHT_WITHOUT_RESIZE_VALUE = `${TEXTAREA_HEIGHT_WITHOUT_RESIZE}px`;

const RESIZE_MIN_HEIGHT = 72;
const RESIZE_MIN_HEIGHT_VALUE = `${RESIZE_MIN_HEIGHT}px`;

const AutoComplete: React.FC<Props> = ({
  options = [],
  value,
  onChange,
  withBorder = false,
  trigger = DEFAULT_TRIGGER,
  regex,
  Component = AutoCompleteComponent.Input,
  placeholder,
  right,
  error,
  onBlur,
  className,
  showError = true,
  changeOnSelect,
  spacer,
  withEmoji = false,
  withAutofocus = false,
  fullHeight = false,
  size,
  maxLength = MAX_TEXT_LENGTH,
  label,
  withoutHandleEnter = false,
  withResize = true,
  reference,
  withMetaKey,
  disabled,
  autoCompleteSize = 'sm',
  fontSize = 'm',
  showErrorText
}: Props) => {
  const ref = React.useRef<any>(null);
  const rootStore = useRootStore();
  const { t } = useTypedTranslation();
  const {
    opened: focused,
    open: focus,
    close: unfocus
  } = useModalState(withAutofocus && !disabled);

  const emojiStore = useLocal(
    () =>
      new EmojiStore({
        rootStore,
        text: value,
        onChange
      })
  );

  React.useEffect(() => {
    emojiStore.updateText(value);
  }, [value]);

  const [isInitialValue, setIsInitialValue] = React.useState(true);
  // показано полностью или нет
  const [isSmallHeight, setIsSmallHeight] = React.useState<boolean | undefined>(
    undefined
  );

  const handleChangeSetIsSmallHeight = React.useCallback(
    () => setIsSmallHeight((p) => !p),
    []
  );

  const changeSmallHeight = React.useCallback(() => {
    if (
      !ref?.current?.refInput?.current ||
      Component !== AutoCompleteComponent.Textarea
    ) {
      return;
    }

    // маленькая textarea без кнопок
    if (isSmallHeight === undefined) {
      return;
    }

    // маленькая textarea с кнопкой расширить
    if (isSmallHeight) {
      ref.current.refInput.current.style.height = RESIZE_MIN_HEIGHT_VALUE;
    }

    // развернутая textarea с кнопкой сузить
    if (!isSmallHeight) {
      const { scrollHeight } = ref.current.refInput.current;
      ref.current.refInput.current.style.height = `${scrollHeight}px`;
    }
  }, [Component, isSmallHeight]);

  const handleFocus = React.useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(() => {
    focus();
  }, []);

  React.useEffect(() => {
    if (ref?.current?.refInput?.current && focused) {
      ref.current.refInput.current.focus();
    }
  }, [focused]);

  React.useEffect(() => {
    if (
      !ref?.current?.refInput?.current ||
      Component !== AutoCompleteComponent.Textarea
    ) {
      return;
    }

    ref.current.refInput.current.style.height =
      TEXTAREA_HEIGHT_WITHOUT_RESIZE_VALUE;
    const { scrollHeight } = ref.current.refInput.current;
    ref.current.refInput.current.style.height = `${scrollHeight}px`;

    if (isInitialValue) {
      if (scrollHeight > RESIZE_MIN_HEIGHT) {
        setIsSmallHeight(true);
      }
      setIsInitialValue(false);
      return;
    }

    if (scrollHeight <= RESIZE_MIN_HEIGHT) {
      setIsSmallHeight(undefined);
    } else {
      setIsSmallHeight(false);
    }
  }, []);

  const actualError = error ?? emojiStore.maxLengthError;
  const isError = !!actualError;

  React.useEffect(changeSmallHeight, [isSmallHeight]);

  const updateCursorPosition = React.useCallback(() => {
    if (ref?.current?.refInput?.current) {
      emojiStore.updatePosition(ref.current.refInput.current.selectionStart);
    }
  }, [emojiStore]);

  //раскрытие окна и фиксирование позиции курсора
  const handleTextareaClick = React.useCallback(() => {
    if (
      !ref?.current?.refInput?.current ||
      Component !== AutoCompleteComponent.Textarea
    ) {
      return;
    }

    const { scrollHeight } = ref.current.refInput.current;

    if (scrollHeight > RESIZE_MIN_HEIGHT) {
      setIsSmallHeight(false);
    }

    if (emojiStore.modalState.opened) {
      emojiStore.modalState.close();
    }

    updateCursorPosition();
  }, [isInitialValue, emojiStore.modalState.opened]);

  React.useEffect(() => {
    emojiStore.setOnChange(onChange);
  }, [onChange]);

  //для перерасчета позиции при навигации стреклами, а так же написание текста
  useEventListener({
    eventName: 'keyup',
    getElement: () => ref?.current?.refInput?.current,
    handler: updateCursorPosition
  });

  useEventListener({
    eventName: 'mousedown',
    getElement: () => document.querySelector('ul.react-autocomplete-input'),
    handler: (e) => {
      e.preventDefault();
    }
  });

  const onEnterClick = React.useCallback(
    (event: KeyboardEvent) => {
      if (!withoutHandleEnter && !event.shiftKey) {
        onBlur?.();
      }
    },
    [onBlur, withoutHandleEnter]
  );

  useEnterClickEventListener({ handler: onEnterClick, withMetaKey });

  const handleBlur = React.useCallback(() => {
    if (ref.current.state.helperVisible) {
      ref.current.resetHelper();
    }
    if (onBlur) {
      onBlur();
    }
    emojiStore.validateMaxLength();
    unfocus();
  }, [onBlur]);

  const hasRightContent = right || isSmallHeight !== undefined || withEmoji;

  return (
    <>
      <WithMeta
        className={className}
        error={showError ? actualError : null}
        label={label}
        reference={reference}
        showErrorText={showErrorText}
      >
        <WithBorder
          focus={focused}
          error={isError}
          disabled={disabled}
          styleName={cn(
            !withBorder && 'input_without-border',
            'auto-complete',
            hasRightContent && 'auto-complete__input_right',
            isSmallHeight && 'input_without-bottom-padding',
            fullHeight && 'input_full-height',
            `auto-complete_${autoCompleteSize}`
          )}
          className="auto-complete-new"
        >
          <TextInput
            disabled={disabled}
            maxLength={maxLength}
            onClick={handleTextareaClick}
            ref={ref}
            options={options}
            value={emojiStore.text}
            Component={Component}
            onChange={emojiStore.updateText}
            onBlur={handleBlur}
            onFocus={handleFocus}
            styleName={cn(
              'auto-complete__input',
              `auto-complete__input_font-size-${fontSize}`
            )}
            regex={regex}
            trigger={trigger}
            spellCheck={false}
            placeholder={placeholder && renderTranslationString(placeholder, t)}
            changeOnSelect={changeOnSelect}
            maxOptions={0}
            offsetY={20}
            spacer={spacer}
            size={size}
            matchAny={true}
          />
          <div styleName="auto-complete__right">
            {right && (
              <div
                styleName={cn(
                  'auto-complete__right-elem',
                  Component === AutoCompleteComponent.Textarea &&
                    'auto-complete__right-elem_top'
                )}
              >
                {right}
              </div>
            )}
            {withResize && isSmallHeight !== undefined && (
              <div
                onClick={handleChangeSetIsSmallHeight}
                styleName="auto-complete__right-options"
              >
                {isSmallHeight ? (
                  <ResizeUp iconStyle={IconStyle.gray} iconSize={IconSize.XM} />
                ) : (
                  <ResizeDown
                    iconStyle={IconStyle.gray}
                    iconSize={IconSize.XM}
                  />
                )}
              </div>
            )}
            {withEmoji && (
              <PopupContainer
                isOpen={emojiStore.modalState.opened}
                onClose={emojiStore.modalState.close}
                trigger={
                  <div
                    styleName={cn(
                      'auto-complete__right-elem auto-complete__right-elem_bottom',
                      isSmallHeight &&
                        'auto-complete__right-elem_with-padding-bottom'
                    )}
                  >
                    <SmileIcon
                      iconStyle={
                        emojiStore.modalState.opened
                          ? IconStyle.blue
                          : IconStyle.gray
                      }
                      action={{
                        type: ComponentType.svg,
                        onClick: emojiStore.modalState.toggle
                      }}
                    />
                  </div>
                }
                on={PopupEventType.click}
                position={[PopupPosition.bottomRight, PopupPosition.topRight]}
              >
                <div styleName="emoji-modal">
                  <Picker
                    onClick={emojiStore.insertEmoji}
                    showPreview={false}
                    title={t('EmojiPicker.title', {
                      ns: 'components'
                    })}
                    emoji=""
                    i18n={DefaultSettingsEmoji(t)}
                  />
                </div>
              </PopupContainer>
            )}
          </div>
        </WithBorder>
      </WithMeta>
    </>
  );
};

export default observer(AutoComplete);
