import { IRootStore } from 'shared/entities/store/rootStore';
import {
  AuthFromParamReservedValue,
  AuthOpeningBaseParams,
  AuthOpeningOpenParam,
  AuthStage,
  getSubDomain,
  ReservedDomain,
  SmartbotAITabs,
  urls
} from 'shared/entities/domain';
import { LocalStorageKey } from 'shared/entities/localStorage';
import { IAuthFlowStore } from 'shared/entities/store/authFlowStore';
import { BaseResponseCode } from 'shared/entities/network';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { ICabinetModel } from 'shared/entities/cabinet';

import { localStorageHandler } from '../localStorageHandler';

export default class AuthFlowStore implements IAuthFlowStore {
  protected rootStore: IRootStore;
  readonly authorizingStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Перенаправляет пользователя на его доступные кабинет и проект.
   * Метод работает только, когда пользователь авторизован
   * @returns {Promise<BaseResponse>}
   */
  async redirectToAvailableCabinetFromAuthDomain(): Promise<BaseResponse> {
    const fromParam = this.rootStore.routerStore.openingParams.from;

    if (fromParam && fromParam !== AuthFromParamReservedValue.oauth) {
      this.rootStore.routerStore.removeOpeningParam(AuthOpeningBaseParams.from);
      window.location.href = fromParam;

      return {
        isError: false
      };
    }

    const cabinetResponse = await this.chooseCabinet();

    if (cabinetResponse.isError) {
      this.rootStore.routerStore.changeToAuthDomain({
        path: urls.AUTH.create(AuthStage.CABINET_CREATION)
      });

      return {
        isError: false
      };
    }

    this.rootStore.routerStore.changeSubDomain({
      subDomain: cabinetResponse.data.domain
    });

    return {
      isError: false
    };
  }

  /**
   * Выбирает id проекта
   * @returns {Promise<void>}
   */
  protected async chooseProject(): Promise<BaseResponse<string>> {
    const { isError } = await this.rootStore.projectsStore.load({
      initial: true
    });

    if (isError) {
      return {
        isError: true
      };
    }

    const lastProjectId = localStorageHandler.getItem(
      LocalStorageKey.lastProjectId
    );

    // Если есть lastProjectId в localStorage и он есть в списке проектов данного кабинета
    if (
      lastProjectId &&
      this.rootStore.projectsStore.projects.getEntity(lastProjectId)
    ) {
      return {
        isError: false,
        data: lastProjectId
      };
    }

    // Если есть проекты, то выбираем первый из списка
    if (this.rootStore.projectsStore.projects.length) {
      return {
        isError: false,
        data: this.rootStore.projectsStore.projects.items[0].id
      };
    }

    return {
      isError: true
    };
  }

  /**
   * Выбор кабинета
   * @returns {Promise<void>}
   */
  protected async chooseCabinet(): Promise<BaseResponse<ICabinetModel>> {
    const response = await this.rootStore.cabinetsStore.load();

    if (response.isError || !response.data.length) {
      return {
        isError: true
      };
    }

    let cabinet: ICabinetModel | null = null;

    /**
     * Выбираем кабинет для открытия, приоритет - кабинет, где не надо показывать страницу с подпиской
     */
    for (let i = 0; i < response.data.length; i++) {
      const curCabinet = response.data[i];

      if (!curCabinet.needToShowSubscriptionPage) {
        cabinet = curCabinet;
        break;
      } else {
        if (!cabinet) {
          cabinet = curCabinet;
        }
      }
    }

    if (!cabinet) {
      return {
        isError: true
      };
    }

    const cabinetResponse = await this.rootStore.initCabinet(cabinet.domain);

    if (cabinetResponse.isError) {
      return {
        isError: true
      };
    }

    return {
      isError: false,
      data: cabinetResponse.data
    };
  }

  getOpenUrl = ({ projectId }: { projectId: string }): string => {
    const defaultUrl = urls.PROJECT.tabs.ONBOARDING.create({ projectId });
    const openParam = this.rootStore.routerStore.openingParams.open;

    if (!openParam) {
      return defaultUrl;
    }

    this.rootStore.routerStore.removeOpeningParam(AuthOpeningBaseParams.open);

    if (
      !Object.values(AuthOpeningOpenParam).includes(
        openParam as AuthOpeningOpenParam
      )
    ) {
      return defaultUrl;
    }

    switch (openParam) {
      case AuthOpeningOpenParam.shopback: {
        return urls.PROJECT.tabs.GROWTH_TOOLS.CREATE_SHOP.create({
          projectId
        });
      }
      case AuthOpeningOpenParam.smartbot_ai: {
        return urls.PROJECT.tabs.SMARTBOT_AI.create({
          projectId: projectId,
          tab: SmartbotAITabs.EDUCATION
        });
      }

      case AuthOpeningOpenParam.nocode_cloud: {
        return urls.PROJECT.tabs.NOCODE_CLOUD.create({
          projectId: projectId
        });
      }
      default: {
        return defaultUrl;
      }
    }
  };

  async authorize(): Promise<BaseResponse> {
    const subDomain = getSubDomain();

    if (subDomain === null) {
      this.rootStore.routerStore.changeToAuthDomain();

      return {
        isError: false
      };
    }

    if (this.authorizingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.authorizingStage.loading();

    if (subDomain === ReservedDomain.auth) {
      const { isError } = await this.initializeFromAuthDomain();

      if (isError) {
        this.authorizingStage.error();
      } else {
        this.authorizingStage.success();
      }

      return {
        isError
      };
    }

    const { isError } = await this.initializeFromCabinetDomain(subDomain);

    if (isError) {
      this.authorizingStage.error();
    } else {
      this.authorizingStage.success();
    }

    return {
      isError
    };
  }

  /**
   * Авторизация с auth-домена
   * @returns {Promise<void | {isError: true}>}
   */
  private async initializeFromAuthDomain(): Promise<BaseResponse> {
    if (!this.rootStore.routerStore.matchPath(urls.AUTH.mask)) {
      this.rootStore.routerStore.push(urls.AUTH.root);
    }

    const { isError } = await this.rootStore.userStore.authorize();

    if (isError) {
      return {
        isError: true
      };
    }

    const fromParam = this.rootStore.routerStore.openingParams.from;

    if (fromParam === AuthFromParamReservedValue.oauth) {
      this.rootStore.routerStore.removeOpeningParam(AuthOpeningBaseParams.from);
      await this.rootStore.userStore.updateRefs();
    }

    const redirectToAvailableCabinet = this.rootStore.routerStore.matchPath(
      [urls.AUTH.root, urls.AUTH.create(AuthStage.ERROR)],
      {
        exact: true
      }
    );

    if (redirectToAvailableCabinet) {
      return this.redirectToAvailableCabinetFromAuthDomain();
    }

    return {
      isError: false
    };
  }

  /**
   * Инициализация кабинета
   * @returns {Promise<void>}
   */
  private async initializeFromCabinetDomain(
    cabinetDomain: string
  ): Promise<BaseResponse> {
    const { isError } = await this.rootStore.userStore.authorize();

    if (isError) {
      this.rootStore.routerStore.changeToAuthDomain({
        params: {
          [AuthOpeningBaseParams.from]: window.location.href
        }
      });

      return {
        isError: true
      };
    }

    const response = await this.rootStore.initCabinet(cabinetDomain);

    // Если такого кабинета нет или у пользователя нет прав, то кидаем на страницу "Кабинет не найден"
    if (response.isError) {
      if (response.data.code === BaseResponseCode.notFound) {
        this.rootStore.routerStore.changeToAuthDomain({
          path: urls.AUTH.create(AuthStage.CABINET_NOT_FOUND)
        });
      }

      return {
        isError: false
      };
    }

    if (response.data.needToShowSubscriptionPage) {
      this.rootStore.routerStore.push(urls.SUBSCRIPTION.root);

      return {
        isError: false
      };
    }

    const projectResponse = await this.chooseProject();

    // если по какой-то причине нет проекта, то открываем настройки кабинета
    if (projectResponse.isError) {
      this.rootStore.routerStore.push(urls.SETTINGS.root);

      return {
        isError: false
      };
    }

    const projectUrl = this.getOpenUrl({
      projectId: projectResponse.data
    });

    // если есть deeplink-параметр, то открываем его
    if (this.rootStore.routerStore.openingParams.open) {
      this.rootStore.routerStore.push(projectUrl);

      return {
        isError: false
      };
    }

    // если не выбран проект и не открываем настройки кабинета, то показываем проект
    if (
      !this.rootStore.routerStore.matchPath(urls.PROJECT.mask) &&
      !this.rootStore.routerStore.matchPath(urls.SETTINGS.mask)
    ) {
      this.rootStore.routerStore.push(projectUrl);
    }

    return {
      isError: false
    };
  }
}
