import * as React from 'react';
import cn from 'classnames';

import { SelectorBaseItem } from 'shared/newEntities/components/Selector';
import Typography from 'shared/newComponents/Typography';
import {
  TypographySize,
  TypographyType,
  TypographyWeight,
  TypographyColor
} from 'shared/newEntities/components/Typography';
import { SelectIcon } from 'shared/newComponents/icons';
import { IconSize } from 'shared/entities/components/Icon';
import Checkbox from 'shared/newComponents/form/Checkbox';
import CroppedContent from 'shared/newComponents/CroppedContent';
import Tag from 'shared/newComponents/Tag';
import { TagProps, TagSize } from 'shared/newEntities/components/Tag';

import './SelectorBaseOption.modules.scss';
import RightIconComponent from '../RightIconComponent';

type Props = SelectorBaseItem & {
  onClick: () => void;
  className?: string;
  selected?: boolean;
  selectView?: 'checkbox' | 'icon';
  largeOption?: boolean; // чтобы текст опции был максимально показан
  tag?: TagProps;
};

const SelectorBaseOption: React.FC<Props> = (props) => {
  const {
    title,
    onClick,
    selected,
    description,
    Icon,
    className,
    rightComponent,
    selectView = 'icon',
    largeOption = false,
    tagProps
  } = props;

  const handleCheckboxClick = React.useCallback(
    (_: boolean, originalEvent: React.MouseEvent<HTMLDivElement>) => {
      originalEvent.stopPropagation();
      onClick();
    },
    [onClick]
  );

  const withCheckbox = selectView === 'checkbox' && selected !== undefined;

  return (
    <div styleName="container">
      <div
        styleName={cn(
          'option',
          selected && 'option_selected',
          largeOption && 'option_large'
        )}
        onClick={onClick}
        className={className}
      >
        <div styleName="option__left">
          <div styleName="option__left-top">
            {withCheckbox && (
              <Checkbox
                styleName="option__checkbox"
                value={selected}
                onChange={handleCheckboxClick}
              />
            )}
            {Icon && <Icon styleName="option__icon" iconSize={IconSize.XM} />}
            <div styleName="option__title-container">
              <CroppedContent
                popupContent={title}
                styleName={cn(
                  'option__title',
                  largeOption && 'option__title_large'
                )}
              >
                <Typography
                  styleName="option__title-text"
                  fontType={TypographyType.text}
                  fontSize={TypographySize.m}
                  fontWeight={TypographyWeight.heavy}
                >
                  {title}
                </Typography>
              </CroppedContent>
              {tagProps && (
                <Tag styleName="option__tag" size={TagSize.xs} {...tagProps} />
              )}
            </div>
          </div>
          {description && (
            <CroppedContent
              popupContent={description}
              styleName={cn(
                'option__description',
                withCheckbox && 'option__description_with-select'
              )}
            >
              <Typography
                styleName="option__description-text"
                fontType={TypographyType.text}
                fontSize={TypographySize.m}
                fontColor={TypographyColor.tertiary}
              >
                {description}
              </Typography>
            </CroppedContent>
          )}
        </div>
        {selectView === 'icon' && selected && (
          <SelectIcon styleName="option__select-icon" iconSize={IconSize.XM} />
        )}
        {rightComponent && (
          <RightIconComponent
            styleName="option__right-icon"
            {...rightComponent}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectorBaseOption);
