import * as React from 'react';
import cn from 'classnames';

import { InputProps } from 'shared/newEntities/components/Input';
import {
  CircledExclamationMarkIcon,
  CircledQuestionIcon,
  CloseEyeIcon,
  EyeIcon
} from 'shared/newComponents/icons';
import { IconSize } from 'shared/entities/components/Icon';
import { ComponentType } from 'shared/entities/components/Component';
import {
  renderTranslationString,
  useTypedTranslation
} from 'shared/entities/localization';
import { useModalState } from 'shared/entities/common/hooks';
import Reference from 'shared/newComponents/Reference';
import Typography from 'shared/newComponents/Typography';
import {
  TypographyColor,
  TypographySize,
  TypographyType
} from 'shared/newEntities/components/Typography';
import CopyComponent from 'shared/newComponents/CopyComponent';

import WithMeta from '../WithMeta';

import styles from './Input.modules.scss';

const Input = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      onFocus,
      value,
      placeholder,
      error,
      label,
      className,
      bottomLabel,
      size = 'sm',
      disabled,
      type = 'text',
      reference,
      withCopying,
      Icon,
      prefix,
      inputStyle,
      errorLimited,
      fontSize = 'm',
      currency,
      withoutBorder,
      forceFocus = false,
      right,
      showErrorText,
      ...rest
    }: InputProps,
    ref: React.MutableRefObject<HTMLInputElement>
  ) => {
    const {
      opened: focused,
      open: focus,
      close: unfocus
    } = useModalState(forceFocus);
    const [innerType, setInnerType] =
      React.useState<React.HTMLInputTypeAttribute>(type);
    const { t } = useTypedTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const actualRef = ref ?? inputRef;

    const handleChangePasswordType = React.useCallback(() => {
      if (type !== 'password') {
        return;
      }
      setInnerType(innerType === 'password' ? 'text' : 'password');
    }, [type, innerType]);

    const handleChange = React.useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (e) => {
        onChange?.(e.target.value, e);
      },
      [onChange]
    );

    const handleBlur = React.useCallback<
      React.FocusEventHandler<HTMLInputElement>
    >(
      (e) => {
        // для того, чтобы handleChangePasswordType вызывался до валидации
        setTimeout(() => onBlur?.(e), 200);
        unfocus();
      },
      [onBlur]
    );

    const handleFocus = React.useCallback<
      React.FocusEventHandler<HTMLInputElement>
    >(
      (e) => {
        onFocus?.(e);
        focus();
      },
      [onFocus]
    );

    React.useEffect(() => {
      if (actualRef.current && focused) {
        actualRef.current.focus();
      }
    }, [focused]);

    const isError = !!error;
    const actualPlaceholder = placeholder
      ? placeholder
      : (t) => t('form.Input.placeholder', { ns: 'newComponents' });

    const content = (
      <div
        styleName={cn(
          'container',
          withoutBorder && 'container_without-border',
          isError && !withoutBorder && 'container_error',
          focused && !withoutBorder && 'container_focused',
          disabled && !withoutBorder && 'container_disabled',
          withCopying && 'container_copying'
        )}
        className={styles[`container_${size}`]}
      >
        <div styleName={cn('left', withoutBorder && 'left_without-border')}>
          {prefix && (
            <Typography
              fontType={TypographyType.text}
              fontSize={TypographySize.m}
              fontColor={TypographyColor.tertiary}
              styleName="left__prefix"
            >
              {prefix}
            </Typography>
          )}
          <div styleName="left__container" style={inputStyle}>
            {Icon && <Icon styleName="left-icon" iconSize={IconSize.XM} />}
            <input
              ref={actualRef}
              {...rest}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={renderTranslationString(actualPlaceholder, t)}
              value={value}
              onFocus={handleFocus}
              styleName={cn('input', `input_font-size-${fontSize}`)}
              type={innerType}
            />
            {right && <div styleName="right">{right}</div>}
            <>
              {type !== 'password' &&
                (isError ? (
                  <CircledExclamationMarkIcon
                    iconSize={IconSize.S}
                    styleName="icon icon_error"
                  />
                ) : (
                  <>
                    {currency && currency}
                    {reference && (
                      <Reference
                        {...reference}
                        trigger={
                          <div styleName="icon icon_reference">
                            <CircledQuestionIcon iconSize={IconSize.S} />
                          </div>
                        }
                      />
                    )}
                  </>
                ))}
              {type === 'password' && (
                <>
                  {innerType === 'password' && (
                    <EyeIcon
                      styleName="icon icon_eye"
                      iconSize={IconSize.S}
                      action={{
                        type: ComponentType.svg,
                        onClick: handleChangePasswordType
                      }}
                    />
                  )}
                  {innerType === 'text' && (
                    <CloseEyeIcon
                      styleName="icon icon_eye"
                      iconSize={IconSize.S}
                      action={{
                        type: ComponentType.svg,
                        onClick: handleChangePasswordType
                      }}
                    />
                  )}
                  {isError && (
                    <CircledExclamationMarkIcon
                      iconSize={IconSize.S}
                      styleName="icon icon_error"
                    />
                  )}
                </>
              )}
            </>
          </div>
        </div>
        {withCopying && (
          <CopyComponent
            value={value}
            styleName={cn('copying', withoutBorder && 'copying_without-border')}
          />
        )}
      </div>
    );

    return (
      <WithMeta
        label={label}
        error={error}
        className={className}
        bottomLabel={bottomLabel}
        errorLimited={errorLimited}
        showErrorText={showErrorText}
      >
        {content}
      </WithMeta>
    );
  }
);

export default Input;
