import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const CodeIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M8 2C7 2 5.5 2.5 5.5 4.5V9C5.5 10.5 3 11.5 3 11.5C3 11.5 5.5 12.5 5.5 14V19.5C5.5 21.5 7 22 8 22M16 2C17 2 18.5 2.5 18.5 4.5V9C18.5 10.5 21 11.5 21 11.5C21 11.5 18.5 12.5 18.5 14V19.5C18.5 21.5 17 22 16 22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CodeIcon;
