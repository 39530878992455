import { ReactElement } from 'react';

import {
  TranslationNode,
  TranslationString
} from 'shared/entities/localization';
import { ButtonProps } from 'shared/newEntities/components/Button';

export enum AppNotificationType {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info'
}

export type AppNotificationOpenParams = {
  title: TranslationNode;
  highlightedParts?: string[];
  type: AppNotificationType;
  button?: ButtonProps;
  breakText?: boolean;
  timeout?: number;
};

export enum BannerPriority {
  high = 'high',
  low = 'low',
  extra = 'extra'
}

export type BannerOpenParams = {
  id: string;
  type: AppNotificationType;
  title: TranslationString | ReactElement;
  link?: {
    title: TranslationString | ReactElement;
    to?: string;
    onClick?: () => void;
    external?: boolean;
  };
  excludePath?: string[];
  onClose?: () => void;
  priority?: BannerPriority;
  highlightedParts?: string[];
  withIcon?: boolean;
};
