import { action, makeObservable, observable } from 'mobx';
import { useHistory, matchPath } from 'react-router-dom';

import { IRouterStore } from 'shared/entities/store/routerStore';
import {
  authOpeningParams,
  AuthOpeningParams,
  AuthOpeningParamsStructure,
  createLocation,
  getSubDomain,
  getUrlFromPathAndParamsObject,
  parseParams,
  ReservedDomain,
  urls,
  UtmTag
} from 'shared/entities/domain';
import { localStorageHandler } from 'stores/localStorageHandler';

export default class RouterStore implements IRouterStore {
  readonly openingParams: AuthOpeningParamsStructure =
    RouterStore.getOpeningParams();

  pathname = '';
  search = '';
  history: ReturnType<typeof useHistory> | null = null;

  constructor() {
    makeObservable(this, {
      pathname: observable,
      search: observable,
      history: observable,
      changePathname: action,
      changeSearch: action,
      changeHistory: action
    });
  }

  getSearchParam = (key: string): string | null => {
    const urlParams = parseParams(window.location.search);
    const value = urlParams[key];
    return value !== undefined ? value : null;
  };

  changePathname = (pathname: string) => {
    this.pathname = pathname;
  };

  changeSearch = (search: string) => {
    this.search = search;
  };

  changeHistory = (history: ReturnType<typeof useHistory>): void => {
    this.history = history;
  };

  matchPath(
    path: string | string[],
    params: { exact: boolean } = { exact: false }
  ): boolean {
    return !!matchPath(this.pathname, {
      exact: params.exact,
      path
    });
  }

  push = (to: string, params: Record<string, string> = {}): void => {
    if (!this.history) {
      return;
    }

    this.history.push(
      getUrlFromPathAndParamsObject(to, {
        ...params
      })
    );
  };

  replace = (to: string) => {
    if (!this.history) {
      return;
    }

    this.history.replace(to);
  };

  get utmParams(): Record<UtmTag | string, string> {
    return Object.values(UtmTag).reduce<Record<UtmTag | string, string>>(
      (acc, utmTag) => {
        const value = this.openingParams[utmTag];
        if (value) {
          acc[utmTag] = value;
        }
        return acc;
      },
      {}
    );
  }

  removeOpeningParam = (param: AuthOpeningParams): void => {
    delete this.openingParams[param];
    localStorageHandler.remove(param);
  };

  removeOpeningParams(): void {
    Object.keys(this.openingParams).forEach((param) =>
      this.removeOpeningParam(param as AuthOpeningParams)
    );
  }

  static getOpeningParams = (): AuthOpeningParamsStructure => {
    const params: AuthOpeningParamsStructure = {};
    const queryParams = parseParams(window.location.search);

    authOpeningParams.forEach((key) => {
      const valueFromQuery = queryParams[key];
      const valueFromLS = localStorageHandler.getItem(key);

      const value = valueFromQuery || valueFromLS;

      if (!value) {
        return;
      }

      params[key] = value;
      localStorageHandler.setItem(key, value);
    });

    return params;
  };

  getUrl = ({
    subDomain,
    path = urls.ROOT.mask,
    params = {}
  }: {
    subDomain: ReservedDomain | string;
    path?: string;
    params?: Record<string, any>;
  }): string => {
    const curSubDomain = getSubDomain();

    const url = getUrlFromPathAndParamsObject(path, {
      ...this.openingParams,
      ...params
    });

    if (curSubDomain === subDomain) {
      return url;
    }

    return createLocation({
      subDomain,
      url
    });
  };

  changeSubDomain = ({
    subDomain,
    path = urls.ROOT.mask,
    params = {}
  }: {
    subDomain: ReservedDomain | string;
    path?: string;
    params?: Record<string, any>;
  }): void => {
    const curSubDomain = getSubDomain();

    const url = getUrlFromPathAndParamsObject(path, {
      ...this.openingParams,
      ...params
    });

    if (curSubDomain === subDomain) {
      this.push(url);
      return;
    }

    // при переходе между домена, удаляем параметры из ls
    this.removeOpeningParams();

    window.location.href = createLocation({
      subDomain,
      url
    });
  };

  changeToAuthDomain = ({
    params,
    path = urls.AUTH.root
  }: {
    path?: string;
    params?: Record<string, any>;
  } = {}): void => {
    this.changeSubDomain({
      subDomain: ReservedDomain.auth,
      params,
      path
    });
  };

  pushToAuth = (): void => {
    this.changeToAuthDomain({ path: urls.AUTH.root });
  };
}
