import * as app from 'App/pages/App/translation';
import * as authPage from 'App/pages/Auth/translation';
import * as AuthModal from 'App/pages/AuthModal/translation';
import * as notAvailableService from 'App/pages/NotAvailable/translation';
import * as header from 'App/pages/PageTemplate/Header/translation';
import * as supportPopup from 'shared/newContainers/SupportPopup/translation';
import * as conversationsPage from 'App/pages/Project/Conversations/translation';
import * as shopbackHandleCategory from 'App/pages/Project/GrowthTools/HandleCategory/translation';
import * as shopbackHandleShop from 'App/pages/Project/GrowthTools/HandleShop/translation';
import * as growthToolsShopPage from 'App/pages/Project/GrowthTools/Shop/translation';
import * as smartbotAIPage from 'App/pages/Project/SmartbotAI/translation';
import * as channelsPage from 'App/pages/Project/Channels/translation';
import * as shopbackHandleProduct from 'App/pages/Project/GrowthTools/ShopbackProduct/translation';
import * as shopbackOverviewProduct from 'App/pages/Project/GrowthTools/OverviewMainProduct/translation';
import * as shops from 'App/pages/Project/GrowthTools/Shops/translation';
import * as shopbackInstruction from 'App/pages/Project/GrowthTools/Shop/Connection/Instruction/translation';
import * as growthTools from 'App/pages/Project/GrowthTools/translation';
import * as integrationsPage from 'App/pages/Project/Integrations/translation';
import * as mailingsPage from 'App/pages/Project/Mailings/translation';
import * as scenarioPage from 'App/pages/Project/Scenario/translation';
import * as scenariosPage from 'App/pages/Project/Scenarios/translation';
import * as projectSettingsPage from 'App/pages/Project/Settings/translation';
import * as usersPage from 'App/pages/Project/Users/translation';
import * as settingsPage from 'App/pages/Settings/CabinetSettings/translation';
import * as smartbotAIBots from 'App/pages/Project/SmartbotAIBots/translation';
import * as components from 'shared/components/translation';
import * as dataFormContainer from 'shared/containers/DataForm/translation';
import * as integrationContainer from 'shared/containers/Integration/translation';
import * as managersContainer from 'shared/containers/managers/translation';
import * as modals from 'shared/containers/modals/translation';
import * as parsingContainer from 'shared/containers/parsing/translation';
import * as selectors from 'shared/containers/selectors/translation';
import * as setVarsComponentContainer from 'shared/containers/SetVarsComponent/translation';
import * as sqEditorContainer from 'shared/containers/SQEditor/translation';
import * as variablesTableContainer from 'shared/containers/modals/VariablesTableModal/translation';
import * as statsContainer from 'shared/containers/StatsContainer/translation';
import * as entities from 'shared/entities/translation';
import * as models from 'shared/models/translation';
import * as smartGraphBlocks from 'shared/SmartGraph/blocks/translation';
import * as smartGraphBlocksSelector from 'shared/SmartGraph/blocksSelectors/translation';
import * as smartGraphComponents from 'shared/SmartGraph/components/translation';
import * as smartGraphSettings from 'shared/SmartGraph/settings/translation';
import * as smartGraphStores from 'shared/SmartGraph/stores/translation';
import * as stores from 'stores/translation';
import * as shopbackStores from 'stores/shopback/translation';
import * as lib from 'lib/translation';
import * as pageTemplate from 'App/pages/PageTemplate/translation';
import * as benefits from 'shared/newComponents/Benefits/translation';
import * as payment from 'shared/newContainers/payment/translation';
import * as onboarding from 'App/pages/Project/Onboarding/translation';
import * as profileSettings from 'App/pages/Settings/ProfileSettings/translation';
import * as newComponents from 'shared/newComponents/translation';
import * as newContainers from 'shared/newContainers/translation';
import * as nocodeCloud from 'App/pages/Project/NocodeCloud/translation';

export const translationNameSpaces = {
  app,
  AuthModal,
  smartGraphBlocks,
  smartGraphBlocksSelector,
  smartGraphComponents,
  smartGraphSettings,
  smartGraphStores,
  lib,
  authPage,
  projectSettingsPage,
  mailingsPage,
  integrationsPage,
  conversationsPage,
  scenariosPage,
  usersPage,
  scenarioPage,
  settingsPage,
  supportPopup,
  growthToolsShopPage,
  shops,
  notAvailableService,
  pageTemplate,
  smartbotAIPage,
  channelsPage,
  smartbotAIBots,
  onboarding,
  profileSettings,
  nocodeCloud,

  // shopback
  shopbackHandleShop,
  shopbackHandleCategory,
  shopbackHandleProduct,
  shopbackOverviewProduct,
  shopbackStores,
  shopbackInstruction,
  growthTools,

  parsingContainer,
  variablesTableContainer,
  integrationContainer,
  managersContainer,
  sqEditorContainer,
  dataFormContainer,
  setVarsComponentContainer,
  statsContainer,

  components,
  stores,
  entities,
  modals,
  selectors,
  header,
  models,

  benefits,
  payment,

  newComponents,
  newContainers
};
