import PubSub from 'pubsub-js';
import { action, computed, makeObservable, observable } from 'mobx';
import { matchPath } from 'react-router';

import { IRootStore } from 'shared/entities/store/rootStore';
import { IAppNotificationsStore } from 'shared/entities/store/appNotificationsStore';
import PubSubObserver from 'lib/PubSubObserver';
import {
  AppNotificationOpenParams,
  AppNotificationType,
  BannerOpenParams,
  BannerPriority
} from 'shared/entities/appNotifications';
import ListModel from 'shared/models/ListModel';
import { FieldModel } from 'shared/models/form';
import { LocalStorageKey } from 'shared/entities/localStorage';
import getBooleanValueFromLS from 'shared/utils/getBooleanValueFromLS';
import { urls } from 'shared/entities/domain';
import { localStorageHandler } from 'stores/localStorageHandler';

import { AppNotificationEvents } from './config';

export const NOCODE_CLOUD_BANNER_ID = '__NOCODE_CLOUD_BANNER__';

export default class AppNotificationsStore
  extends PubSubObserver
  implements IAppNotificationsStore
{
  readonly banners: ListModel<BannerOpenParams> =
    new ListModel<BannerOpenParams>();

  rootStore: IRootStore;

  initialized = false;

  readonly forbiddenErrorOpened: FieldModel<boolean> = new FieldModel<boolean>(
    false
  );

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;

    makeObservable(this, {
      initialized: observable,

      bannerOpened: computed,
      banner: computed,

      openBanner: action,
      closeBanner: action.bound,
      setInitialized: action
    });
  }

  setInitialized = (value: boolean): void => {
    this.initialized = value;
  };

  get sortedBanners(): BannerOpenParams[] {
    const errors: BannerOpenParams[] = [];
    const warnings: BannerOpenParams[] = [];
    const successes: BannerOpenParams[] = [];
    const infoBanners: BannerOpenParams[] = [];
    const extraBanners: BannerOpenParams[] = [];

    this.banners.items.forEach((banner) => {
      if (banner.priority === BannerPriority.extra) {
        extraBanners.push(banner);
        return;
      }
      if (banner.type === AppNotificationType.error) {
        errors.push(banner);
        return;
      }
      if (banner.type === AppNotificationType.warning) {
        warnings.push(banner);
        return;
      }
      if (banner.type === AppNotificationType.success) {
        successes.push(banner);
        return;
      }
      if (banner.type === AppNotificationType.info) {
        infoBanners.push(banner);
        return;
      }
    });

    this.sortBanners(errors);
    this.sortBanners(warnings);
    this.sortBanners(successes);
    this.sortBanners(infoBanners);

    return [
      ...extraBanners,
      ...errors,
      ...warnings,
      ...infoBanners,
      ...successes
    ];
  }

  get banner(): BannerOpenParams | null {
    if (!this.banners.length) {
      return null;
    }

    return this.sortedBanners[0];
  }

  get bannerOpened(): boolean {
    return (
      !!this.banner &&
      !(this.banner.excludePath || []).some((path) =>
        matchPath(this.rootStore.routerStore.pathname, path)
      )
    );
  }

  private sortBanners = (banners: BannerOpenParams[]) => {
    banners.sort((a, b) => {
      if (a.priority === b.priority) {
        return 0;
      }
      if (a.priority === BannerPriority.high) {
        return -1;
      }
      if (a.priority === BannerPriority.low) {
        return +1;
      }

      return 0;
    });
  };

  open(params: AppNotificationOpenParams): void {
    PubSub.publish(AppNotificationEvents.open, params);
  }

  openBanner(banner: BannerOpenParams): void {
    this.banners.addEntity({
      entity: banner,
      key: banner.id
    });
  }

  openForbiddenError(params: AppNotificationOpenParams): void {
    if (this.forbiddenErrorOpened.value) {
      return;
    }
    this.forbiddenErrorOpened.changeValue(true);
    PubSub.publish(AppNotificationEvents.openForbiddenError, params);
  }

  setForbiddenErrorClosed = (): void => {
    this.forbiddenErrorOpened.changeValue(false);
  };

  closeOpenedBanner = (): void => {
    if (!this.banner) {
      return;
    }

    this.banner.onClose?.();
    this.banners.removeEntity(this.banner.id);
  };

  closeBanner(bannerId: string): void {
    const banner = this.banners.getEntity(bannerId);

    if (!banner) {
      return;
    }

    this.banners.removeEntity(banner.id);
  }

  openNocodeCloudBanner = (onClick: () => void) => {
    if (this.rootStore.cabinetId && this.rootStore.userStore.user) {
      const bannerWasClosed =
        getBooleanValueFromLS(LocalStorageKey.nocodeCloudBannerWasClosed) ??
        false;
      if (!bannerWasClosed) {
        this.openBanner({
          type: AppNotificationType.success,
          excludePath: [urls.AUTH.mask, urls.PROJECT.tabs.NOCODE_CLOUD.mask],
          title: (t) =>
            t('AppNotificationsStore.nocodeCloudBanner.text', { ns: 'stores' }),
          id: NOCODE_CLOUD_BANNER_ID,
          link: {
            title: (t) =>
              t('AppNotificationsStore.nocodeCloudBanner.link', {
                ns: 'stores'
              }),
            onClick
          },
          onClose: () => {
            localStorageHandler.setItem(
              LocalStorageKey.nocodeCloudBannerWasClosed,
              'true'
            );
          },
          priority: BannerPriority.high
        });
      }
    }
  };

  closeNocodeCloudBanner = () => {
    this.closeBanner(NOCODE_CLOUD_BANNER_ID);
  };
}
