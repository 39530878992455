import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const SmartbotAIIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M14.17 20.8899C18.354 20.6129 21.686 17.2329 21.96 12.9899C22.013 12.1599 22.013 11.2999 21.96 10.4699C21.686 6.22794 18.354 2.84994 14.17 2.57094C12.7249 2.4757 11.2751 2.4757 9.82997 2.57094C5.64597 2.84894 2.31397 6.22794 2.03997 10.4709C1.987 11.3101 1.987 12.1518 2.03997 12.9909C2.13997 14.5359 2.82297 15.9669 3.62797 17.1749C4.09497 18.0199 3.78697 19.0749 3.29997 19.9979C2.94997 20.6629 2.77397 20.9949 2.91497 21.2349C3.05497 21.4749 3.36997 21.4829 3.99897 21.4979C5.24397 21.5279 6.08297 21.1759 6.74897 20.6849C7.12597 20.4059 7.31497 20.2669 7.44497 20.2509C7.57497 20.2349 7.83197 20.3409 8.34397 20.5509C8.80397 20.7409 9.33897 20.8579 9.82897 20.8909C11.254 20.9849 12.743 20.9849 14.171 20.8909"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 15L9.342 9.474C9.38824 9.33609 9.47664 9.2162 9.59471 9.13126C9.71278 9.04632 9.85455 9.00062 10 9.00062C10.1455 9.00062 10.2872 9.04632 10.4053 9.13126C10.5234 9.2162 10.6118 9.33609 10.658 9.474L12.5 15M15.5 9V15M8.5 13H11.5"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default SmartbotAIIcon;
