import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const MiniMapIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M8.66667 6.1157V21.7925M15.3333 2V17.834M2 8.458C2 6.79091 2 5.95849 2.43667 5.47216C2.59121 5.29916 2.77922 5.16054 2.98889 5.065C3.58 4.79809 4.35667 5.06161 5.91 5.58866C7.09556 5.99129 7.68778 6.19261 8.28778 6.17112C8.50788 6.16375 8.72673 6.1342 8.94111 6.0829C9.52444 5.94153 10.0444 5.58866 11.0844 4.88404L12.62 3.84126C13.9533 2.93647 14.6189 2.48407 15.3833 2.38115C16.1478 2.27596 16.9078 2.53383 18.4267 3.04957L19.7211 3.48839C20.8211 3.86162 21.3711 4.04824 21.6856 4.49272C22 4.9372 22 5.52871 22 6.70721V15.9011C22 17.5671 22 18.4006 21.5633 18.8869C21.4086 19.0591 21.2206 19.197 21.0111 19.2918C20.42 19.5599 19.6433 19.2963 18.09 18.7693C16.9044 18.3667 16.3122 18.1654 15.7122 18.1868C15.4921 18.1942 15.2733 18.2238 15.0589 18.2751C14.4756 18.4164 13.9556 18.7693 12.9156 19.4739L11.38 20.5167C10.0467 21.4215 9.38111 21.8739 8.61667 21.9768C7.85222 22.082 7.09222 21.8241 5.57333 21.3084L4.27889 20.8696C3.17889 20.4963 2.62889 20.3097 2.31444 19.8652C2 19.4208 2 18.8292 2 17.6507V8.458Z"
        stroke="currentColor"
        strokeWidth="1.66965"
      />
    </BaseIcon>
  );
};

export default MiniMapIcon;
