import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const SQIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M7 16H8.9C9.46005 16 9.74008 16 9.95399 15.891C10.1422 15.7951 10.2951 15.6422 10.391 15.454C10.5 15.2401 10.5 14.9601 10.5 14.4V13.6C10.5 13.0399 10.5 12.7599 10.391 12.546C10.2951 12.3578 10.1422 12.2049 9.95399 12.109C9.74008 12 9.46005 12 8.9 12H8.6C8.03995 12 7.75992 12 7.54601 11.891C7.35785 11.7951 7.20487 11.6422 7.10899 11.454C7 11.2401 7 10.9601 7 10.4V9.4C7 8.83995 7 8.55992 7.10899 8.34601C7.20487 8.15785 7.35785 8.00487 7.54601 7.90899C7.75992 7.8 8.03995 7.8 8.6 7.8H10.5M17 16H15.1C14.5399 16 14.2599 16 14.046 15.891C13.8578 15.7951 13.7049 15.6422 13.609 15.454C13.5 15.2401 13.5 14.9601 13.5 14.4V12V9.6C13.5 9.03995 13.5 8.75992 13.609 8.54601C13.7049 8.35785 13.8578 8.20487 14.046 8.10899C14.2599 8 14.5399 8 15.1 8H15.4C15.9601 8 16.2401 8 16.454 8.10899C16.6422 8.20487 16.7951 8.35785 16.891 8.54601C17 8.75992 17 9.03995 17 9.6V16ZM17 16L18 17M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default SQIcon;
