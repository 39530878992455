export const MAX_VAR_WITH_PERCENT_LENGTH = 64; // длина переменной, допустимая беком
export const MAX_VAR_WITHOUT_PERCENT_LENGTH = MAX_VAR_WITH_PERCENT_LENGTH - 2; // длина переменной без %(62)
const MAX_VAR_LENGTH = MAX_VAR_WITHOUT_PERCENT_LENGTH - 1; // вычитаем 1(для правильного расчета в регулярке)(61)

export const VAR_BODY_REG_EXP_PATTERN = `[^%<>&\\s][^%&<>\n]{0,${MAX_VAR_LENGTH}}`;
export const VAR_REGEXP_PATTERN = `%${VAR_BODY_REG_EXP_PATTERN}%`;

export const VAR_REG_EXP_WORD = new RegExp(`^${VAR_REGEXP_PATTERN}$`);
export const VAR_REG_EXP_GLOBALLY = new RegExp(VAR_REGEXP_PATTERN, 'g');

export const VAR_BODY_REG_EXP_WORD = new RegExp(
  `^${VAR_BODY_REG_EXP_PATTERN}$`
);
