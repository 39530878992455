import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';
import { ButtonProps } from 'shared/newEntities/components/Button';
import {
  ContentModalProps,
  ModalSize
} from 'shared/newEntities/components/modals';

import ContentModal from '../ContentModal';

import Content from './Content';

type Props = {
  ok: ButtonProps;
  className?: string;
  rightFooter?: boolean;
  footnotes?: TranslationNode;
  cancel?: ButtonProps;
  opened: boolean;
  onEnter?: () => void;
} & ContentModalProps;

const BaseFormModal: React.FC<Props> = ({
  children,
  ok,
  onEnter,
  rightFooter,
  footnotes,
  cancel,
  opened,
  fixHeight = false,
  size = ModalSize.xs,
  ...rest
}: Props) => {
  return (
    <ContentModal opened={opened} fixHeight={fixHeight} size={size} {...rest}>
      <Content
        opened={opened}
        ok={ok}
        onEnter={onEnter}
        rightFooter={rightFooter}
        footnotes={footnotes}
        cancel={cancel}
      >
        {children}
      </Content>
    </ContentModal>
  );
};

export default BaseFormModal;
