import { TFunctionType } from 'shared/entities/localization';

export type Emoji = {
  id: string;
  name: string;
  colons: string;
  text: string;
  emoticons: string[];
  skin: string;
  native: string;
  short_names: string[];
  unified: string;
};

export type I18n = {
  search?: string;
  clear?: string; // Accessible label on "clear" button
  notfound?: string;
  skintext?: string;
  categories?: {
    search?: string;
    recent?: string;
    smileys?: string;
    people?: string;
    nature?: string;
    foods?: string;
    activity?: string;
    places?: string;
    objects?: string;
    symbols?: string;
    flags?: string;
    custom?: string;
  };
  categorieslabel?: 'Emoji categories'; // Accessible title for the list of categories
  skintones?: {
    1: SkinTones.DefaultSkinTone;
    2: SkinTones.LightSkinTone;
    3: SkinTones.MediumLightSkinTone;
    4: SkinTones.MediumSkinTone;
    5: SkinTones.MediumDarkSkinTone;
    6: SkinTones.DarkSkinTone;
  };
};

export enum SkinTones {
  DefaultSkinTone = 'Default Skin Tone',
  LightSkinTone = 'Light Skin Tone',
  MediumLightSkinTone = 'Medium-Light Skin Tone',
  MediumSkinTone = 'Medium Skin Tone',
  MediumDarkSkinTone = 'Medium-Dark Skin Tone',
  DarkSkinTone = 'Dark Skin Tone'
}

export const DefaultSettingsEmoji = (t: TFunctionType): I18n => ({
  search: t('EmojiPicker.search', {
    ns: 'components'
  }),
  notfound: t('EmojiPicker.notfound', {
    ns: 'components'
  }),
  categories: {
    search: t('EmojiPicker.categories.search', {
      ns: 'components'
    }),
    recent: t('EmojiPicker.categories.recent', {
      ns: 'components'
    }),
    smileys: t('EmojiPicker.categories.smileys', {
      ns: 'components'
    }),
    people: t('EmojiPicker.categories.people', {
      ns: 'components'
    }),
    nature: t('EmojiPicker.categories.nature', {
      ns: 'components'
    }),
    foods: t('EmojiPicker.categories.foods', {
      ns: 'components'
    }),
    activity: t('EmojiPicker.categories.activity', {
      ns: 'components'
    }),
    places: t('EmojiPicker.categories.places', {
      ns: 'components'
    }),
    objects: t('EmojiPicker.categories.objects', {
      ns: 'components'
    }),
    symbols: t('EmojiPicker.categories.symbols', {
      ns: 'components'
    }),
    flags: t('EmojiPicker.categories.flags', {
      ns: 'components'
    })
  }
});
