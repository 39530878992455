import * as React from 'react';

import { MAX_TEXT_LENGTH, ValidatorResult } from 'shared/entities/validator';

const useMaxLengthValidator = (
  maxLength?: number | null,
  onBlur?: (e) => void
): {
  maxLengthValidatorProps:
    | { onBlur?: (e) => void }
    | { maxLength: number; onKeyUp: (e) => void; onBlur: (e) => void };
  maxLengthError: ValidatorResult;
} => {
  const actualMaxLength =
    maxLength === null
      ? undefined
      : maxLength === undefined
      ? MAX_TEXT_LENGTH
      : maxLength;

  const [error, setError] = React.useState<ValidatorResult>(null);
  const handleKeyUp = React.useCallback((e) => {
    if (!actualMaxLength) {
      return;
    }
    if (e.target.value.length >= actualMaxLength) {
      setError(
        () => (t) =>
          t('validators.errors.maxLengthField', {
            ns: 'entities',
            maxLength: actualMaxLength
          })
      );
    } else {
      setError(null);
    }
  }, []);
  const handleBlur = React.useCallback(
    (e) => {
      setError(null);
      onBlur?.(e);
    },
    [onBlur]
  );

  if (!actualMaxLength) {
    return {
      maxLengthValidatorProps: { onBlur },
      maxLengthError: null
    };
  }
  return {
    maxLengthValidatorProps: {
      maxLength: actualMaxLength,
      onKeyUp: handleKeyUp,
      onBlur: handleBlur
    },
    maxLengthError: error
  };
};

export default useMaxLengthValidator;
