import * as React from 'react';
import cn from 'classnames';

import { SelectIcon } from 'shared/newComponents/icons';
import WithBorder from 'shared/newComponents/WithBorder';
import { useModalState } from 'shared/entities/common/hooks';
import { IconSize } from 'shared/entities/components/Icon';
import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import Typography from 'shared/newComponents/Typography';
import { TranslationNode } from 'shared/entities/localization';
import { WithMetaProps } from 'shared/newEntities/components/WithMeta';
import { ReferenceProps } from 'shared/newEntities/components/Reference';
import Reference from 'shared/newComponents/Reference';
import { CheckboxType } from 'shared/newEntities/components/Checkbox';

import WithMeta from '../WithMeta';

import './Checkbox.modules.scss';

export type Props = Omit<WithMetaProps, 'reference'> & {
  value: boolean;
  onChange: (
    value: boolean,
    originalEvent: React.MouseEvent<HTMLDivElement>
  ) => void;
  className?: string;
  disabled?: boolean;
  children?: TranslationNode;
  reference?: ReferenceProps;
  aligned?: boolean;
  type?: CheckboxType;
  metaReference?: ReferenceProps;
};

const Checkbox: React.FC<Props> = ({
  value,
  onChange,
  className,
  disabled,
  children,
  reference,
  aligned,
  type = CheckboxType.rect,
  metaReference,
  ...metaProps
}) => {
  const { opened: focused, open: focus, close: unfocus } = useModalState();

  const handleChange = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      onChange(!value, e);
    },
    [onChange, value]
  );

  const isError = !!metaProps.error;

  return (
    <WithMeta {...metaProps} reference={metaReference} className={className}>
      <div styleName={cn('container', aligned && 'container_aligned')}>
        <div
          tabIndex={0}
          onFocus={focus}
          onBlur={unfocus}
          onClick={handleChange}
          styleName={cn(
            'container',
            aligned && 'container_aligned',
            disabled && 'container_disabled'
          )}
        >
          <WithBorder
            error={isError}
            focus={focused}
            disabled={disabled}
            borderRadius={type === CheckboxType.rect ? 4 : 'circle'}
            styleName="checkbox-container"
          >
            <div styleName="checkbox">
              {type === CheckboxType.rect ? (
                <div
                  styleName={cn(
                    'checkbox__icon',
                    disabled && 'checkbox__icon_disabled'
                  )}
                >
                  {value && <SelectIcon iconSize={IconSize.XSM} />}
                </div>
              ) : (
                <>
                  {value && (
                    <div
                      styleName={cn(
                        'checkbox__circle',
                        disabled && 'checkbox__circle_disabled'
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </WithBorder>
          {children && (
            <>
              <Typography
                styleName="container__title"
                fontType={TypographyType.text}
                fontSize={TypographySize.s}
                fontWeight={TypographyWeight.heavy}
                fontColor={TypographyColor.secondary}
              >
                {children}
              </Typography>
            </>
          )}
        </div>
        {!!reference && (
          <Reference {...reference} styleName="container__reference" />
        )}
      </div>
    </WithMeta>
  );
};

export default React.memo(Checkbox);
