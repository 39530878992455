import { action, computed, makeObservable, observable } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { Emoji } from 'shared/entities/emoji';
import { OpenStateModel } from 'shared/models/openState';
import {
  MAX_TEXT_LENGTH,
  ValidatorResult,
  maxLengthErrorText,
  validateTextFieldMaxLength
} from 'shared/entities/validator';

import { convertEmoji } from './utils';

export default class EmojiStore {
  readonly rootStore: IRootStore;
  readonly modalState: OpenStateModel = new OpenStateModel();

  private _position: number;
  private _text: string;
  private _onChange: (value: string) => void;
  private _maxLengthError: ValidatorResult | null = null;

  constructor({
    rootStore,
    text,
    onChange
  }: {
    rootStore: IRootStore;
    text: string;
    onChange: (value: string) => void;
  }) {
    this.rootStore = rootStore;
    this._text = text;
    this.updatePosition(this._text.length);
    this.validateMaxLength();
    this._onChange = onChange;

    makeObservable<
      EmojiStore,
      '_position' | '_text' | '_maxLengthError' | 'validateTextLength'
    >(this, {
      rootStore: observable,
      _position: observable,
      _text: observable,
      _maxLengthError: observable,
      modalState: observable,

      updatePosition: action,
      updateText: action,
      insertEmoji: action,
      validateTextLength: action,
      validateMaxLength: action,

      text: computed,
      position: computed
    });
  }

  updateText = (value: string): void => {
    if (value === this._text) {
      return;
    }

    this._onChange(value);
    this._text = value;
  };

  setOnChange = (onChange: (value: string) => void): void => {
    this._onChange = onChange;
  };

  insertEmoji = (selectedEmoji: Emoji): void => {
    const emoji = convertEmoji(selectedEmoji);
    this.updateText(
      this._text.slice(0, this._position) +
        emoji +
        this._text.slice(this._position)
    );
    this.updatePosition(this._position + emoji.length);
  };

  get position(): number {
    return this._position;
  }

  get text(): string {
    return this._text;
  }

  get maxLengthError() {
    return this._maxLengthError;
  }

  updatePosition = (value: number): void => {
    this.validateTextLength();
    this._position = value;
  };

  // метод показывающий ошибку, если курсор в поле ввода и длина текста уже 4096
  private validateTextLength = (): void => {
    if (this._text.length >= MAX_TEXT_LENGTH) {
      this._maxLengthError = maxLengthErrorText;
    } else {
      this._maxLengthError = null;
    }
  };

  validateMaxLength = () => {
    this._maxLengthError = validateTextFieldMaxLength(this._text);
  };
}
