import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const LinkIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M7.5755 11.3137L16.4244 11.3137M8.42453 14.8892L5.74289 14.8892C3.76819 14.8892 2.16738 13.2884 2.16737 11.3137C2.16737 9.33896 3.76818 7.73815 5.74288 7.73815L8.42451 7.73816M15.5755 14.8892L18.2572 14.8892C20.2319 14.8892 21.8327 13.2884 21.8327 11.3137C21.8327 9.339 20.2319 7.73819 18.2572 7.73819L15.5755 7.73818"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default LinkIcon;
