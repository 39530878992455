import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import { LoadingStage } from 'shared/entities/meta';
import { ComponentType } from 'shared/entities/components/Component';
import {
  ButtonProps,
  ButtonSize,
  ButtonColor,
  ButtonStyle,
  mapButtonSizeToIconSize,
  mapButtonSizeToLoaderSpinnerSize,
  buttonFontProps,
  mapButtonColorToLoaderColor,
  ButtonContentPosition
} from 'shared/newEntities/components/Button';
import Typography from 'shared/newComponents/Typography';
import { TypographyWeight } from 'shared/newEntities/components/Typography';

import styles from './Button.modules.scss';

const Button = React.forwardRef<any | null, ButtonProps>((props, ref) => {
  const {
    className,
    children,
    disabled,
    loadingStage,
    fullWidth = false,
    size = ButtonSize.md,
    color = ButtonColor.blue,
    style = ButtonStyle.common,
    Icon,
    RightIcon,
    transparentBorder = false,
    withoutPadding = false,
    contentPosition = ButtonContentPosition.center,
    withoutBorder = false,
    iconStyle,
    fontWeight
  } = props;

  //показываем иконку слева, если не загрузка
  //если загрузка, то только когда есть текст у кнопки
  const showLeftIcon =
    Icon &&
    ((loadingStage === LoadingStage.LOADING && !!children) ||
      loadingStage !== LoadingStage.LOADING);

  //показываем иконку справа, если не загрузка
  //если загрузка, то только когда есть текст у кнопки
  const showRightIcon =
    RightIcon &&
    ((loadingStage === LoadingStage.LOADING && !!children) ||
      loadingStage !== LoadingStage.LOADING);

  const iconSize = mapButtonSizeToIconSize[size];
  const fontProps = {
    fontWeight:
      fontWeight ??
      (size === ButtonSize.xxl
        ? TypographyWeight.bold
        : TypographyWeight.semiBold),
    ...buttonFontProps[size]
  };
  const loaderSize = mapButtonSizeToLoaderSpinnerSize[size];

  const content = (
    <>
      {showLeftIcon && (
        <div styleName="button__icon">
          <Icon iconSize={iconSize} iconStyle={iconStyle} />
        </div>
      )}
      {children && (
        <Typography {...fontProps} fontColor={null}>
          {children}
        </Typography>
      )}
      {loadingStage === LoadingStage.LOADING && (
        <LoaderSpinner
          styleName="button__loader"
          color={mapButtonColorToLoaderColor[color]}
          size={loaderSize}
        />
      )}
      {showRightIcon && (
        <div styleName="button__icon button__icon_right">
          <RightIcon iconSize={iconSize} />
        </div>
      )}
    </>
  );

  const styleName = cn(
    'button',
    `button_${size}`,
    `button_content_${contentPosition}`,
    `button_content_${contentPosition}`,
    style === ButtonStyle.common && 'button_common',
    fullWidth && 'button_full',
    transparentBorder && 'button_transparent-border',
    !children && !withoutPadding && 'button_without-content',
    withoutPadding && 'button_without-padding',
    withoutBorder && 'button_without-border'
  );

  const actualClassName = cn(
    style === ButtonStyle.transparent && styles[`button_transparent-${color}`],
    style === ButtonStyle.common && styles[`button_${color}`],
    className
  );

  const commonProps = {
    className: actualClassName,
    disabled
  };

  switch (props.type) {
    case ComponentType.link:
      return (
        <Link
          styleName={styleName}
          {...commonProps}
          to={props.to}
          ref={ref}
          onClick={props.onClick}
          target={props.target}
        >
          {content}
        </Link>
      );
    case ComponentType.a: {
      const extraProps =
        props.target === '_blank'
          ? {
              rel: 'noopener noreferrer'
            }
          : {};
      return (
        <a
          styleName={styleName}
          {...commonProps}
          href={props.href}
          target={props.target}
          {...extraProps}
          ref={ref}
          onClick={props.onClick}
        >
          {content}
        </a>
      );
    }
    case ComponentType.button:
      return (
        <button
          styleName={styleName}
          {...commonProps}
          type="button"
          onClick={props.onClick}
          ref={ref}
        >
          {content}
        </button>
      );
  }
});

export default React.memo(Button);
