import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const ChannelIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      {...props}
    >
      <path
        d="M4 14L5.57465 20.2986C5.61893 20.4757 5.64107 20.5643 5.66727 20.6417C5.92317 21.3972 6.60352 21.9284 7.39852 21.9934C7.4799 22 7.5712 22 7.75379 22C7.98244 22 8.09677 22 8.19308 21.9907C9.145 21.8984 9.89834 21.145 9.99066 20.1931C10 20.0968 10 19.9825 10 19.7538V5.50003M18.5 13.5C20.433 13.5 22 11.933 22 10C22 8.06704 20.433 6.50003 18.5 6.50003M10.25 5.50003H6.5C4.01472 5.50003 2 7.51475 2 10C2 12.4853 4.01472 14.5 6.5 14.5H10.25C12.0164 14.5 14.1772 15.4469 15.8443 16.3557C16.8168 16.8858 17.3031 17.1509 17.6216 17.1119C17.9169 17.0757 18.1402 16.9431 18.3133 16.7011C18.5 16.4402 18.5 15.918 18.5 14.8737V5.12632C18.5 4.08203 18.5 3.55988 18.3133 3.29892C18.1402 3.05694 17.9169 2.92433 17.6216 2.88816C17.3031 2.84916 16.8168 3.11423 15.8443 3.64439C14.1772 4.55315 12.0164 5.50003 10.25 5.50003Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ChannelIcon;
