import * as React from 'react';

import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

import BaseIcon from '../BaseIcon';

const PortalOutIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      svgProps={{ viewBox: '0 0 24 24' }}
      {...props}
    >
      <path
        d="M15.9307 18.1717C16.3689 18.6267 17.0766 18.6267 17.5148 18.1717L22.6715 12.8167C22.7756 12.7087 22.8582 12.5805 22.9146 12.4394C22.971 12.2983 23 12.147 23 11.9942C23 11.8414 22.971 11.6901 22.9146 11.5489C22.8582 11.4078 22.7756 11.2796 22.6715 11.1717L17.5148 5.81667C17.4109 5.70851 17.2874 5.62271 17.1515 5.56416C17.0156 5.50562 16.8699 5.47548 16.7228 5.47548C16.5756 5.47548 16.4299 5.50562 16.294 5.56416C16.1581 5.62271 16.0347 5.70851 15.9307 5.81667C15.5038 6.27167 15.4926 7.01833 15.9307 7.47333L19.1663 10.8333H6.61728C5.99938 10.8333 5.49382 11.3583 5.49382 12C5.49382 12.6417 5.99938 13.1667 6.61728 13.1667H19.1663L15.9307 16.5267C15.8266 16.6346 15.7439 16.7628 15.6876 16.9039C15.6312 17.0451 15.6022 17.1964 15.6022 17.3492C15.6022 17.502 15.6312 17.6533 15.6876 17.7944C15.7439 17.9355 15.8266 18.0637 15.9307 18.1717ZM2.12346 19C2.74136 19 3.24691 18.475 3.24691 17.8333V6.16667C3.24691 5.525 2.74136 5 2.12346 5C1.50555 5 1 5.525 1 6.16667V17.8333C1 18.475 1.50555 19 2.12346 19Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PortalOutIcon;
