import * as React from 'react';
import { AnchorSpec } from 'jsplumb';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import { Point } from 'shared/entities/geometry';
import { Color } from 'shared/entities/color';
import { TranslationString } from 'shared/entities/localization';
import { ValidatorResult } from 'shared/entities/validator';

export enum SmartBlockType {
  input = 'input',
  action = 'action',
  condition = 'condition'
}

export enum SmartEndpointColor {
  blue = 'blue',
  gray = 'gray',
  green = 'green',
  red = 'red'
}

export const blocksColorMap: Record<SmartBlockType, Color> = {
  [SmartBlockType.condition]: Color.COLOR_BLUE_6,
  [SmartBlockType.input]: Color.COLOR_WHITE,
  [SmartBlockType.action]: Color.COLOR_GRAY_1
};

export type SmartSource = {
  blockId: string;
  portName: string;
};

export type SmartSourceParams = {
  color: SmartEndpointColor;
  anchors?: AnchorSpec;
  nested?: boolean;
};

export type SmartTarget = {
  blockId: string;
};

export type SmartBlockProps = {
  onMouseUp: (params: { e: MouseEvent; wasDragged: boolean }) => void;
  onMouseDown: (e: MouseEvent) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  active: boolean;
  position: Point;
  prevPosition: Point;
  Icon: React.FC<CommonIconProps>;
  label: TranslationString;
  id: string;
  rightOptions?: React.ReactNode;
  stats?: React.ReactNode;
  error?: ValidatorResult;
};

export const DEFAULT_BLOCK_ICON_SIZE = IconSize.XM;
