import * as React from 'react';
import cn from 'classnames';

import TransComp from 'shared/components/TransComp';
import {
  TypographySize,
  TypographyTag,
  TypographyProps,
  TypographyWeight,
  TypographyColor,
  TypographyType
} from 'shared/newEntities/components/Typography';

import styles from './Typography.modules.scss';

function TypographyInner<T extends TypographyTag | undefined>(
  {
    fontWeight = TypographyWeight.normal,
    fontSize = TypographySize.s,
    fontColor = TypographyColor.main,
    fontType = TypographyType.text,
    children,
    underline = false,
    className,
    style,
    ...rest
  }: TypographyProps<T>,
  ref: React.ForwardedRef<
    T extends TypographyTag ? HTMLElementTagNameMap[T] : HTMLDivElement
  >
) {
  const El = rest.Element || 'div';

  const actualFontColor =
    fontColor !== null ? fontColor || TypographyColor.main : fontColor;

  const actualClassName = cn(
    actualFontColor && styles[`typography_${actualFontColor}`],
    styles[`typography-${fontType}_${fontSize}`],
    className
  );

  const actualStyle = {
    fontWeight,
    ...style
  };

  return (
    // @ts-ignore
    <El
      styleName={cn(
        `typography-${fontType}`,
        underline && 'typography_underline'
      )}
      className={actualClassName}
      style={actualStyle}
      ref={ref}
      {...rest}
    >
      {children && <TransComp>{children}</TransComp>}
    </El>
  );
}
const Typography = React.forwardRef(TypographyInner);

export default Typography;
